import { createSelector } from 'reselect';

const searchTabSelector = (state) => state.searchState.searchTab;

const searchTermSelector = (state) => state.searchState.searchTerm;

export const getSearchTab = createSelector(
  searchTabSelector,
  (searchTab) => searchTab
);

export const getSearchTerm = createSelector(
  searchTermSelector,
  (searchTerm) => searchTerm
);
