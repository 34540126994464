import React from "react";
import CreditItem from "./CreditItem";
import { CREDIT_TYPES } from "common/model/creditType";

export const ArticulationCreditList = (props) => {
  const groupedArticulations = props.groupedArticulations || {};
  const articulationKeys = Object.keys(groupedArticulations) || [];
  const creditBookmarks = props.creditBookmarks;
  // const articulations = props.articulations || [];
  //   console.log(articulationKeys);
  // console.log(groupedArticulations);
  const isMobile = props.isMobile;
  if (isMobile) {
    return articulationKeys.map((articulation) => {
      return (
        <CreditItem
          key={`credit-item-${articulation.ID}`}
          itemBlocks={groupedArticulations[articulation]}
          creditType={CREDIT_TYPES.ARTICULATION}
          selectedSourceInstitution={props.selectedSourceInstitution}
          creditBookmarks={creditBookmarks}
          isMobile={isMobile}
        />
      );
    });
  }

  return articulationKeys.map((articulationKey) => {
    return (
      <div
        id="articulation-credit-list"
        style={{ marginBottom: 12 }}
        key={articulationKey}
      >
        <CreditItem
          itemBlocks={groupedArticulations[articulationKey]}
          selectedSourceInstitution={props.selectedSourceInstitution}
          creditType={CREDIT_TYPES.ARTICULATION}
          creditBookmarks={creditBookmarks}
          isMobile={isMobile}
        />
      </div>
    );
  });
};

export const PrecedentCreditList = (props) => {
  const precedents = props.precedents || [];
  const creditBookmarks = props.creditBookmarks;
  const isMobile = props.isMobile;

  if (isMobile) {
    return precedents.map((precedent) => (
      <CreditItem
        key={precedent.ID}
        itemBlocks={[precedent]}
        creditType={CREDIT_TYPES.PRECEDENT}
        selectedSourceInstitution={props.selectedSourceInstitution}
        creditBookmarks={creditBookmarks}
        isMobile={isMobile}
      />
    ));
  }
  return precedents.map((precedent) => {
    return (
      <div style={{ marginBottom: 12 }} key={precedent.ID}>
        <CreditItem
          itemBlocks={[precedent]}
          creditType={CREDIT_TYPES.PRECEDENT}
          selectedSourceInstitution={props.selectedSourceInstitution}
          creditBookmarks={creditBookmarks}
          isMobile={isMobile}
        />
      </div>
    );
  });
};
