import React from "react";
import { Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";

const BackToResultButton = ({ isMobile }) => {
  return (
    <Link to="/home" className="back-to-result-button">
      <span style={{ position: "relative", top: 4, marginRight: 16 }}>
        <ChevronLeft size={20} />
      </span>
      <span>{isMobile ? "Back" : "Back to all results"}</span>
    </Link>
  );
};

export default BackToResultButton;
