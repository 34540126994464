export const isApplicationFinishedLoadingSelector = (state) => {
  if (!state) {
    return false;
  }
  const urlsIsFetched =
    // !_.isEmpty(state.urlReducer.urls) &&
    !state.urlReducer.fetching && !state.urlReducer.error;
  const creditArrangementsIsFetched =
    // !_.isEmpty(state.creditArrangementState.creditArrangements) &&
    !state.creditArrangementState.fetching;
  //  &&
  // !state.creditArrangementState.error;
  const metaDataIsFetched =
    // !_.isEmpty(state.metadataState.metadata) &&
    !state.metadataState.fetching;
  // && !state.metadataState.error;
  return urlsIsFetched && creditArrangementsIsFetched && metaDataIsFetched;
};
