import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { cloneDeep, orderBy, sortBy } from "lodash";
import { API } from "common/http/axiosClient";
import {
  fetchSourceInstitutionsSuccess,
  fetchSourceInstitutionsError,
  fetchSourceInstitutions,
} from "../source-institution/sourceInstitutionAction";
import {
  fetchCreditArrangementsSuccess,
  fetchCreditArrangementsError,
  fetchCreditArrangements,
} from "../credit-arrangement/creditArrangementAction";
import { getTotalCreditPoints } from "utils/creditUtils";
import { transformToEntityMap } from "common/redux/redux-utils";
import { FETCH_EXTERNAL_DATA } from "store/external-data/externalDataAction";
import {
  fetchMetadata,
  fetchMetadataError,
  fetchMetadataSuccess,
} from "store/metadata/metadataAction";
import { selectUrls } from "store/custom-url/urlSelector";

export function* doFetchExternalData() {
  try {
    yield all([
      put(fetchMetadata()),
      put(fetchSourceInstitutions()),
      put(fetchCreditArrangements()),
    ]);
    const currentURLs = yield select(selectUrls);
    const { metadata, sourceInstitutions, articulations, precedents } =
      yield call(API.getExternalData, currentURLs.endpoint.url);
    const sortedInstitutionsByCountry = sortBy(
      sourceInstitutions,
      "countryName",
    );
    const srcInstDataMap = transformToEntityMap(
      sortedInstitutionsByCountry,
      "ID",
    );
    // Filter out guaranteed entry articulations for now
    // TODO: Remove this filter later
    const filteredArticulations = articulations.filter(
      (articulation) =>
        !(
          articulation.guaranteedEntry === true &&
          getTotalCreditPoints(articulation) === 0
        ),
    );
    const sortedArticulations = getSortedArticulations(filteredArticulations);
    const sortedPrecedents = getSortedPrecedents(precedents);
    const creditArrangementsMap = getCreditArrangementsMap({
      articulations: sortedArticulations,
      precedents: sortedPrecedents,
    });
    yield all([
      put(fetchMetadataSuccess(metadata)),
      put(fetchSourceInstitutionsSuccess(srcInstDataMap)),
      put(fetchCreditArrangementsSuccess(creditArrangementsMap)),
    ]);
  } catch (error) {
    yield all([
      put(fetchMetadataError()),
      put(fetchSourceInstitutionsError()),
      put(fetchCreditArrangementsError()),
    ]);
  }
}

export function* doFetchCreditArrangments() {
  try {
    const { articulations, precedents } = yield call(API.getCreditArrangements);
    const sortedArticulations = getSortedArticulations(articulations);
    const sortedPrecedents = getSortedPrecedents(precedents);
    const creditArrangementsMap = getCreditArrangementsMap({
      articulations: sortedArticulations,
      precedents: sortedPrecedents,
    });
    yield put(fetchCreditArrangementsSuccess(creditArrangementsMap));
  } catch (error) {
    yield put(fetchCreditArrangementsError());
  }
}

function getSortedArticulations(articulations) {
  return orderBy(
    articulations,
    [
      (a) => {
        return a.credentials[0].unitName;
      },
      (b) => {
        return getTotalCreditPoints(b);
      },
      (c) => {
        return c.course.name;
      },
    ],
    ["asc", "desc", "asc"],
  );
}

function getSortedPrecedents(precedents) {
  return orderBy(
    precedents,
    [
      (a) => {
        return a.credentials
          .map(
            (credential) =>
              `${credential.data.unitName} ${credential.data.unitCode}`,
          )
          .join(" ");
      },
      (b) => {
        return getTotalCreditPoints(b);
      },
      (c) => {
        return c.subjects
          .map((subject) => `${subject.name} ${subject.ID}`)
          .join(" ");
      },
    ],
    ["asc", "desc", "asc"],
  );
}

function getCreditArrangementsMap(data) {
  const clonedData = cloneDeep(data);
  const articulationsMap = transformArticulationsToMap(
    clonedData.articulations,
    {},
  );
  const precedentsMap = transformPrecdentsToMap(
    clonedData.precedents,
    articulationsMap,
  );
  return precedentsMap;
}

function transformArticulationsToMap(articulations, initialMap) {
  const articulationsMap = articulations.reduce(
    (articulationsMap, articulation) => {
      const newArticulationsMap = { ...articulationsMap };
      const credentialIssuerID =
        articulation.credentials[0].data.credentialIssuerID;
      const currentMapEntry = newArticulationsMap[credentialIssuerID];
      if (currentMapEntry) {
        // If the map already has this issuerID
        newArticulationsMap[credentialIssuerID] = {
          ...currentMapEntry,
          articulations: [...currentMapEntry.articulations, articulation],
        };
      } else {
        // Otherwise make new entry in the map
        newArticulationsMap[credentialIssuerID] = {
          articulations: [articulation],
          precedents: [],
        };
      }
      return newArticulationsMap;
    },
    initialMap,
  );
  return articulationsMap;
}

function transformPrecdentsToMap(precedents, initialMap) {
  const precedentsMap = precedents.reduce((precedentsMap, precedent) => {
    const newPrecedentsMap = { ...precedentsMap };
    const credentialIssuerID = precedent.credentials[0].data.credentialIssuerID;
    const currentMapEntry = newPrecedentsMap[credentialIssuerID];
    if (currentMapEntry) {
      // If the map already has this issuerID
      newPrecedentsMap[credentialIssuerID] = {
        ...currentMapEntry,
        precedents: [...currentMapEntry.precedents, precedent],
      };
    } else {
      // Otherwise make new entry in the map
      newPrecedentsMap[credentialIssuerID] = {
        precedents: [precedent],
        articulations: [],
      };
    }
    return newPrecedentsMap;
  }, initialMap);
  return precedentsMap;
}

const externalDataSaga = [takeLatest(FETCH_EXTERNAL_DATA, doFetchExternalData)];

export default externalDataSaga;
