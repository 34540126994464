import {
  FETCH_CREDIT_ARRANGEMENTS,
  FETCH_CREDIT_ARRANGEMENTS_SUCCESS,
  FETCH_CREDIT_ARRANGEMENTS_ERROR,
} from './creditArrangementAction';

const initialState = {
  creditArrangements: {},
  fetching: false,
  error: false,
};

const creditArrangementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREDIT_ARRANGEMENTS: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }

    case FETCH_CREDIT_ARRANGEMENTS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        creditArrangements: action.creditArrangements,
      };
    }

    case FETCH_CREDIT_ARRANGEMENTS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default creditArrangementReducer;
