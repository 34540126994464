import React from "react";
import { Button, Spin } from "antd";
import { selectUrls } from "store/custom-url/urlSelector";
import { connect } from "react-redux";
import _ from "lodash";

const ContactButton = ({ urls, style }) => {
  const contactButtonKey = "contact";
  const contactData = urls[contactButtonKey];
  if (_.isEmpty(urls) || _.isEmpty(contactData)) return <Spin />;

  const { url, alt } = contactData;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      alt={alt}
      style={style}
    >
      <Button type="primary" size="large" className="contact-us-button">
        Contact us
      </Button>
    </a>
  );
};

const mapStoreToProps = (store) => {
  return {
    urls: selectUrls(store),
  };
};

export default connect(mapStoreToProps)(ContactButton);
