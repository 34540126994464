import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "common/ui/card/Card";
import { Row, Col, Tooltip } from "antd";
import { ArrowRight, Heart, ArrowDown } from "react-feather";
import { withRouter } from "react-router-dom";
import i from "i18next";

import classes from "./CreditItem.module.scss";
import {
  getTotalCreditPoints,
  isCreditItemExistsInBookmarks,
  isArticulation,
} from "utils/creditUtils";
import SubjectDetailList from "./SubjectDetailList";
import {
  addCreditToBookmark,
  removeCreditFromBookmark,
} from "store/credit-bookmark/creditBookmarkAction";
import { SUBJECT_TYPES } from "common/model/creditType";
import { get, isEmpty } from "lodash";

const sortItemBlocksByCreditPointsTargetName = (a, b) => {
  const sumReducer = (acc, curr) => {
    return acc + curr.specified + curr.unspecified;
  };
  const aTotal = a.arrangements.reduce(sumReducer, 0);
  const bTotal = b.arrangements.reduce(sumReducer, 0);
  if (aTotal !== bTotal) {
    return bTotal - aTotal;
  } else {
    const aName = a.course && a.course.name ? a.course.name : "";
    const bName = b.course && b.course.name ? b.course.name : "";
    return aName.localeCompare(bName);
  }
};

const CreditItem = (props) => {
  const creditType = props.creditType;
  const isCreditArticulation = isArticulation(creditType);
  const selectedSourceInstitution = props.selectedSourceInstitution || {};
  const creditBookmarks = props.creditBookmarks;
  const isMobile = props.isMobile;
  const unsortedItemBlocks = props.itemBlocks || [];
  const itemBlocks = unsortedItemBlocks.sort(
    sortItemBlocksByCreditPointsTargetName,
  );
  // for mobile

  const {
    metadata: { timestamp },
  } = useSelector((state) => state.metadataState);
  const dispatch = useDispatch();

  const handleClick = (event, clickedBlock) => {
    const targetTagType = event.target.tagName.toLowerCase();
    const parentTagType =
      event.target.parentElement &&
      event.target.parentElement.tagName.toLowerCase();
    const disableGoto = targetTagType === "a" || parentTagType === "a";
    if (disableGoto) {
      event.stopPropagation();
    } else {
      if (!isEmpty(clickedBlock) && clickedBlock.ID) {
        goToCreditItemDetails(clickedBlock.ID);
      } else {
        goToCreditItemDetails(clickedBlock.ID);
      }
    }
  };

  const goToCreditItemDetails = (creditItemID) => {
    props.history.push(`/details/${creditType.toLowerCase()}/${creditItemID}`);
  };

  const addCreditItemToBookmark = (creditItemID) => {
    //Add timestamp
    dispatch(
      addCreditToBookmark(
        selectedSourceInstitution.ID,
        creditItemID,
        creditType,
        timestamp,
      ),
    );
  };

  const removeCreditItemFromBookmark = (creditItemID) => {
    dispatch(
      removeCreditFromBookmark(
        selectedSourceInstitution.ID,
        creditItemID,
        creditType,
      ),
    );
  };

  const getTargetSubjectContentMarkup = (itemBlock) => {
    if (isCreditArticulation) {
      const displayName = get(itemBlock, "course.name", "");
      const courseUrl = get(itemBlock, "course.url", null);
      const displayNameMarkup = <span>{displayName}</span>;
      return (
        <div className={classes.targetSubjectRow}>
          {courseUrl ? (
            <a
              href={courseUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.targetSubjectExternalLink}
            >
              <span className="target-subject-display-name">
                {displayNameMarkup}
              </span>
              <img
                src={`config/icons/${i.t("common.icons.external-link")}`}
                alt="institution logo"
                className={classes.targetSubjectExternalLinkIcon}
              />
            </a>
          ) : (
            displayNameMarkup
          )}
        </div>
      );
    }

    return <SubjectDetailList subjects={itemBlock.subjects} />;
  };

  const getSourceSubjectContentMarkup = (itemBlock) => {
    if (isCreditArticulation) {
      // [      {itemBlocks[0]?.credentials[0]?.data?.unitCode}]
      // TODO need to add previous qual name for source
      if (itemBlock.credentials.length === 1) {
        const displayName = itemBlock.credentials[0].data.unitName;
        return (
          <span className={`${classes.topTitle} top-title`}>
            {displayName} [{itemBlock.credentials[0]?.data?.unitCode}]
          </span>
        );
      } else {
        return (
          <div>
            {itemBlock.credentials.map((cred, i) => {
              const displayName = cred.data.unitName;
              const last = i === itemBlock.credentials.length - 1;
              return (
                <div key={displayName}>
                  <div className={`${classes.topTitle} top-title`}>
                    {displayName}
                  </div>
                  {last ? null : (
                    <div
                      style={{
                        fontSize: 12,
                        color: "rgb(99, 115, 129)",
                      }}
                    >
                      AND
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
    }
    const subjects = itemBlock?.credentials?.map((credential) => ({
      ID: credential.data.unitCode,
      name: credential.data.unitName,
    }));
    return <SubjectDetailList subjects={subjects} />;
  };

  const getCohortContentMarkup = (itemBlock, subjectType) => {
    if (!isCreditArticulation) {
      return null;
    }
    const cohorts =
      subjectType === SUBJECT_TYPES.SOURCE
        ? get(itemBlock, "credentials[0].data.cohorts", [])
        : get(itemBlock, "course.cohorts", []);
    if (cohorts.length === 0) {
      return null;
    }
    return (
      <div className={`${classes.cohortRow} cohort-row`}>
        <span>For:</span>
        {cohorts.map((cohort) => {
          const label = i.t(`common.cohorts.${cohort}.label`);
          const icon = i.t(`common.cohorts.${cohort}.icon`);
          const alt = i.t(`common.cohorts.${cohort}.alt`);
          const filter = i.t(`common.cohorts.${cohort}.filter`);
          if (!(label || icon || alt || filter)) {
            return null;
          }
          return (
            <div
              key={`${itemBlock.ID}-${cohort}`}
              className={`${classes.cohort} cohort`}
            >
              <img
                src={`config/icons/${i.t(`common.icons.${icon}`)}`}
                alt={alt}
                width={16}
                style={{ filter: filter }}
              />
              {label}
            </div>
          );
        })}
      </div>
    );
  };

  const AdditionalDetailsLabel = ({ itemBlock }) => {
    const creditPoints = getTotalCreditPoints(itemBlock);
    let additionalDetailsLabel;
    if (
      itemBlock.guaranteedEntry &&
      !itemBlock.admissionCriteria &&
      creditPoints === 0
    ) {
      additionalDetailsLabel = "Guaranteed entry only!";
    } else if (!itemBlock.guaranteedEntry && itemBlock.admissionCriteria) {
      additionalDetailsLabel = "Admission criteria applies";
    }
    if (!additionalDetailsLabel) {
      return null;
    } else {
      return (
        <div className={classes.additionalDetails}>
          <img
            src={`config/icons/${i.t("common.icons.info-filled")}`}
            alt={"information"}
            width={16}
            className={`${classes.infoFilledIcon} additional-details-icon`}
          />
          <div className={`${classes.caption} caption`}>
            {additionalDetailsLabel}
          </div>
        </div>
      );
    }
  };

  const getArticulationDetailsMarkupContent = (
    itemBlock,
    creditItemExistsInBookmark,
  ) => {
    const creditPoints = getTotalCreditPoints(itemBlock);

    return (
      <div className={`${classes.articulationDetailsMarkupContent}`}>
        <div>
          {creditPoints > 0 && (
            <div
              className={[classes.creditPointsContainer, classes.topTitle].join(
                " ",
              )}
            >
              {creditPoints}
              <div className={`${classes.creditPoints} credit-points`}>
                Credit points
              </div>
            </div>
          )}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.heartIconContainer}>
            <Tooltip
              placement="bottom"
              title={
                <span className={classes.saveToolTipContent}>
                  {creditItemExistsInBookmark ? "Remove from saved" : "Save"}
                </span>
              }
            >
              <Heart
                size={18}
                className={
                  creditItemExistsInBookmark
                    ? ["heart-icon-filled", classes.heartIcon].join(" ")
                    : [classes.heartIcon, classes.heartIconOutline].join(" ")
                }
                onClick={() =>
                  !creditItemExistsInBookmark
                    ? addCreditItemToBookmark(itemBlock.ID)
                    : removeCreditItemFromBookmark(itemBlock.ID)
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const getCricosCodeMarkup = (itemBlock) => {
    const cricosCodes = get(itemBlock, "course.cricosCodes", []);
    if (cricosCodes.length === 0) {
      return null;
    }
    const cricosString = `CRICOS: ${itemBlock.course.cricosCodes.join(", ")}`;
    return (
      <div
        className={`${classes.bottomDescriptionNoMarginTop} description-bottom-no-margin-top`}
      >
        {cricosString}
      </div>
    );
  };

  if (isMobile) {
    return (
      <div>
        {itemBlocks.map((itemBlock, index) => {
          const itemExistsInBookmarks = isCreditItemExistsInBookmarks(
            creditBookmarks,
            selectedSourceInstitution.ID,
            itemBlock.ID,
            creditType,
          );
          return (
            <div key={`${itemBlock.ID}-${index}`} style={{ marginBottom: 20 }}>
              <Row
                className={`${classes.mobileCreditItemDetailContainer} custom-credit-item`}
              >
                <Col
                  span={20}
                  onClick={(event) => handleClick(event, itemBlock)}
                >
                  <div>{getSourceSubjectContentMarkup(itemBlocks[0])}</div>
                  <div
                    className={`${classes.bottomDescription} bottom-description`}
                  >
                    {selectedSourceInstitution?.displayName}
                  </div>
                  {getCohortContentMarkup(itemBlock, SUBJECT_TYPES.SOURCE)}
                  <div className={classes.mobileArrowDown}>
                    <ArrowDown size={18} className={classes.iconStyle} />
                  </div>
                  <div>{getTargetSubjectContentMarkup(itemBlock)}</div>
                  <div
                    className={`${classes.bottomDescription} bottom-description`}
                  >
                    {i.t("institution")}
                  </div>
                </Col>
                <Col span={4}>
                  <div className={classes.mobileHeart}>
                    <Heart
                      size={18}
                      className={
                        itemExistsInBookmarks
                          ? ["heart-icon-filled", classes.heartIcon].join(" ")
                          : [classes.heartIcon, classes.heartIconOutline].join(
                              " ",
                            )
                      }
                      onClick={() =>
                        !itemExistsInBookmarks
                          ? addCreditItemToBookmark(itemBlock.ID)
                          : removeCreditItemFromBookmark(itemBlock.ID)
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <Card zeroPadding className="credit-card" style={{ marginBottom: 12 }}>
      <div className="credit-card-container">
        <Row>
          <Col lg={10} md={24} className="credit-card-credential">
            <div className={`${classes.sourceSideContainer} `}>
              <div className="source-subject-display">
                {getSourceSubjectContentMarkup(itemBlocks[0])}
              </div>
              <div
                className={`${classes.bottomDescription} bottom-description`}
              >
                {selectedSourceInstitution?.displayName}
              </div>
              <div className={`${classes.cohortRow} cohort-row`}>
                {getCohortContentMarkup(itemBlocks[0], SUBJECT_TYPES.SOURCE)}
              </div>
            </div>
          </Col>
          <Col lg={14} md={24} className="credit-card-target">
            {itemBlocks.map((itemBlock) => {
              const creditItemExistsInBookmark = isCreditItemExistsInBookmarks(
                creditBookmarks,
                selectedSourceInstitution.ID,
                itemBlock.ID,
                creditType,
              );
              return (
                <Row
                  key={itemBlock.ID}
                  className={`${classes.targetSideContainer}`}
                >
                  <Col span={2}>
                    <ArrowRight size={18} className={classes.iconStyle} />
                  </Col>
                  <Col span={22}>
                    {/* adding div here for easier reading */}
                    <div>
                      <Row>
                        <Col
                          span={15}
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleClick(event, itemBlock)}
                        >
                          {getTargetSubjectContentMarkup(itemBlock)}
                          <div
                            className={`${classes.bottomDescriptionNoMarginTop} description-bottom-no-margin-top`}
                          >
                            {i.t("institution")}
                          </div>
                          {getCricosCodeMarkup(itemBlock)}
                        </Col>
                        <Col span={9}>
                          {getArticulationDetailsMarkupContent(
                            itemBlock,
                            creditItemExistsInBookmark,
                          )}
                        </Col>
                      </Row>
                      <div className={classes.creditItemBlockFooter}>
                        <div>
                          {getCohortContentMarkup(
                            itemBlock,
                            SUBJECT_TYPES.TARGET,
                          )}
                        </div>
                        <AdditionalDetailsLabel itemBlock={itemBlock} />
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default withRouter(CreditItem);
