import React from "react";
import { connect } from "react-redux";
import i from "i18next";
import { Row, Col } from "antd";
import { getCreditBookmarks } from "store/credit-bookmark/creditBookmarkSelector";
import {
  getCreditArrangementsMap,
  isFetchingCreditArrangements,
} from "store/credit-arrangement/creditArrangementSelector";
import {
  isFetchingSourceInstitutions,
  getSourceInstitutionsMap,
} from "store/source-institution/sourceInstitutionSelector";
import { isNil, isEmpty, get } from "lodash";
import {
  PrecedentCreditList,
  ArticulationCreditList,
} from "components/credits/CreditList";
import NoSavedCredit from "./NoSavedCredit";
import classes from "./SavedCreditItems.module.scss";
import { isArticulation } from "utils/creditUtils";
import { CREDIT_TYPES } from "common/model/creditType";
import BackToResultButton from "common/ui/button/BackToResultButton";
import FullPageSpinner from "common/ui/loading/FullPageSpinner";

import { isApplicationFinishedLoadingSelector } from "utils/generalUtils";

import _ from "lodash";

class SavedCreditItems extends React.Component {
  extractComparableSourceString = (item) => {
    let credentials = [];
    if (item.grouped) {
      if (_.isArray(item.grouped) && !_.isEmpty(item.grouped)) {
        // because this is same across all items
        credentials = item.grouped[0] && item.grouped[0].credentials;
      }
    } else {
      credentials = item.credentials;
    }
    const extractedString = credentials
      .map((c) => {
        if (c.data) {
          return c.data.unitName || "";
        } else {
          return "";
        }
      })
      .join(" ");
    return extractedString;
  };
  sortBySourceCourseName = (a, b) => {
    const aString = this.extractComparableSourceString(a);
    const bString = this.extractComparableSourceString(b);
    return aString.localeCompare(bString);
  };
  groupByCreditType = (creditItemsMap) => {
    const groupedCreditItems = Object.keys(creditItemsMap).reduce(
      (creditItems, institution) => {
        const newCreditItems = { ...creditItems };
        for (const creditItem of creditItemsMap[institution]) {
          if (creditItem.grouped) {
            newCreditItems.ARTICULATION = newCreditItems.ARTICULATION
              ? [
                  ...newCreditItems.ARTICULATION,
                  { ...creditItem, institutionID: institution },
                ]
              : [{ ...creditItem, institutionID: institution }];
          } else if (newCreditItems["PRECEDENT"]) {
            newCreditItems["PRECEDENT"] = [
              ...newCreditItems["PRECEDENT"],
              { ...creditItem, institutionID: institution },
            ];
          } else {
            newCreditItems["PRECEDENT"] = [
              { ...creditItem, institutionID: institution },
            ];
          }
        }
        return newCreditItems;
      },
      {},
    );
    return groupedCreditItems;
  };
  render() {
    // if (
    //   this.props.isFetchingSourceInstitutions ||
    //   this.props.isFetchingCreditArrangements
    // ) {
    //   return (
    //     <LoadingOutlined
    //       style={{ fontSize: 50 }}
    //       className="loading-outlined"
    //     />
    //   );
    // }

    const {
      creditBookmarks,
      creditArrangementsMap,
      sourceInstitutionsMap,
      isMobile,
    } = this.props;

    const creditItemsMap = this.transformBookmarksToCreditItemsMap(
      creditBookmarks,
      creditArrangementsMap,
    );
    // console.log("credit bookmarks", creditBookmarks);
    // console.log("creditArrangementsMap", creditArrangementsMap);

    // console.log("credit items map", creditItemsMap);

    const simpleCreditItemsMap = this.transformBookmarksToSimpleCreditItemsMap(
      creditBookmarks,
      creditArrangementsMap,
    );
    // const hasMissingCredentials = !Object.keys(simpleCreditItemsMap).every(
    //   (sourceInstitutionId) =>
    //     _.isEmpty(creditArrangementsMap[sourceInstitutionId]),
    // );

    // console.log("simple credit items", simpleCreditItemsMap);

    // const typeGroupedCreditsMap = this.groupByCreditType(creditItemsMap);
    if (isEmpty(creditBookmarks)) {
      return (
        <>
          <Row className="title-section">
            <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
            <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
              <div className={`classes.noSavedCreditContainer`}>
                <NoSavedCredit />
              </div>
            </Col>
          </Row>
          <div
            id={isMobile ? "saved-section-mobile" : "saved-section"}
            style={{ marginTop: "18px" }}
          >
            <Row>
              <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
              <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
                <div className={classes.navContainer}>
                  <BackToResultButton isMobile={isMobile} />
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    }

    return (
      <div className={classes.savedCreditContainer}>
        <Row className="title-section">
          <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
          <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
            <div>
              <div className={`${classes.title} page-title`}>SAVED</div>
              {i
                .t("saved.description", { returnObjects: true })
                .map((li, i) => (
                  <p key={`${i}${li}`}>{li}</p>
                ))}
            </div>
          </Col>
        </Row>

        {!this.props.applicationHasLoaded ? (
          <FullPageSpinner />
        ) : (
          <div id={isMobile ? "saved-section-mobile" : "saved-section"}>
            <Row>
              <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
              <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
                <div className={classes.navContainer}>
                  <BackToResultButton isMobile={isMobile} />
                </div>
                {/* {hasMissingCredentials && (
                  <Alert
                    type="error"
                    message="Missing data in datasource!"
                    style={{ marginBottom: "16px" }}
                  />
                )} */}
                {isMobile
                  ? (Object.keys(simpleCreditItemsMap) || [])
                      .sort((a, b) => a.localeCompare(b))
                      .map((sourceInstitutionID) => {
                        const creditItems =
                          creditItemsMap[sourceInstitutionID] || [];
                        // console.log("credit items", creditItemsMap);
                        return (
                          <div
                            key={sourceInstitutionID}
                            className={classes.groupedCreditContainer}
                          >
                            <div
                              className={`${classes.sourceInstitutionLabel}`}
                            >
                              {sourceInstitutionID}
                            </div>
                            <div className={classes.mobileLineSeparator}></div>
                            <>
                              {simpleCreditItemsMap[sourceInstitutionID]
                                .error ? (
                                <div className={classes.savedItems}>
                                  No credit items found for this institution!
                                </div>
                              ) : (
                                creditItems
                                  .sort(this.sortBySourceCourseName)
                                  .map((creditItem, index) => {
                                    if (creditItem.grouped) {
                                      return (
                                        <ArticulationCreditList
                                          key={index}
                                          groupedArticulations={creditItem}
                                          articulations={creditItem}
                                          selectedSourceInstitution={
                                            sourceInstitutionsMap[
                                              sourceInstitutionID
                                            ]
                                          }
                                          creditBookmarks={creditBookmarks}
                                          isMobile={isMobile}
                                        />
                                      );
                                    }
                                    // console.log(index);
                                    // console.log(creditItems);
                                    return isArticulation(creditItem.type) ? (
                                      <ArticulationCreditList
                                        key={index}
                                        groupedArticulations={creditItem}
                                        articulations={creditItem}
                                        selectedSourceInstitution={
                                          sourceInstitutionsMap[
                                            sourceInstitutionID
                                          ]
                                        }
                                        creditBookmarks={creditBookmarks}
                                        isMobile={isMobile}
                                      />
                                    ) : (
                                      <PrecedentCreditList
                                        key={index}
                                        precedents={[creditItem]}
                                        selectedSourceInstitution={
                                          sourceInstitutionsMap[
                                            sourceInstitutionID
                                          ]
                                        }
                                        creditBookmarks={creditBookmarks}
                                        isMobile={isMobile}
                                      />
                                    );
                                  })
                              )}
                            </>
                          </div>
                        );
                      })
                  : (
                      Object.keys(simpleCreditItemsMap).sort((a, b) =>
                        a.localeCompare(b),
                      ) || []
                    ).map((sourceInstitutionID) => {
                      const creditItems =
                        creditItemsMap[sourceInstitutionID] || [];
                      return (
                        <div
                          key={sourceInstitutionID}
                          className={classes.groupedCreditContainer}
                        >
                          <div
                            className={`${classes.sourceInstitutionLabel} source-institution-label`}
                          >
                            {sourceInstitutionID}
                          </div>
                          {simpleCreditItemsMap[sourceInstitutionID].error ? (
                            <div className={classes.savedItems}>
                              No credit items found for this institution!
                            </div>
                          ) : (
                            <div className={classes.savedItems}>
                              {creditItems
                                .sort(this.sortBySourceCourseName)
                                .map((creditItem, index) => {
                                  if (creditItem.grouped) {
                                    return (
                                      <ArticulationCreditList
                                        key={index}
                                        groupedArticulations={creditItem}
                                        articulations={creditItem}
                                        selectedSourceInstitution={
                                          sourceInstitutionsMap[
                                            sourceInstitutionID
                                          ]
                                        }
                                        creditBookmarks={creditBookmarks}
                                        isMobile={isMobile}
                                      />
                                    );
                                  }
                                  return isArticulation(creditItem.type) ? (
                                    <ArticulationCreditList
                                      key={index}
                                      groupedArticulations={creditItem}
                                      articulations={creditItem}
                                      selectedSourceInstitution={
                                        sourceInstitutionsMap[
                                          sourceInstitutionID
                                        ]
                                      }
                                      creditBookmarks={creditBookmarks}
                                      isMobile={isMobile}
                                    />
                                  ) : (
                                    <PrecedentCreditList
                                      key={index}
                                      precedents={[creditItem]}
                                      selectedSourceInstitution={
                                        sourceInstitutionsMap[
                                          sourceInstitutionID
                                        ]
                                      }
                                      creditBookmarks={creditBookmarks}
                                      isMobile={isMobile}
                                    />
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      );
                    })}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }

  transformBookmarksToSimpleCreditItemsMap = (
    creditBookmarks,
    creditArrangementsMap,
  ) => {
    const creditItemsMap = {};
    Object.keys(creditBookmarks).forEach((sourceInstitutionID) => {
      const creditItems = [];
      const creditItemsFromBookmarks =
        creditBookmarks[sourceInstitutionID] || [];
      const creditArrangements = creditArrangementsMap[sourceInstitutionID];
      if (_.isEmpty(creditArrangements)) {
        // console.info("No credit arrangements");
        //comment this out if you dont want to display anything
        // creditItemsMap[sourceInstitutionID] = {
        //   type: "ERROR",
        //   error: "Not found",
        // };
      } else {
        creditItemsFromBookmarks.forEach((creditItemFromBookmarks) => {
          if (isArticulation(creditItemFromBookmarks.creditType)) {
            const articulations = creditArrangements.articulations || [];
            const foundArticulation = articulations.find(
              (articulation) =>
                articulation.ID === creditItemFromBookmarks.creditItemID,
            );
            creditItems.push({
              ...foundArticulation,
              type: CREDIT_TYPES.ARTICULATION,
            });
          } else {
            const precedents = creditArrangements.precedents || [];
            const foundPrecedent = precedents.find(
              (precedent) =>
                precedent.ID === creditItemFromBookmarks.creditItemID,
            );
            creditItems.push({
              ...foundPrecedent,
              type: CREDIT_TYPES.PRECEDENT,
            });
          }
        });
        creditItemsMap[sourceInstitutionID] = creditItems;
      }
    });

    return creditItemsMap;
  };

  transformBookmarksToCreditItemsMap = (
    creditBookmarks,
    creditArrangementsMap,
  ) => {
    if (_.isEmpty(creditArrangementsMap)) {
      return [];
    }
    const creditItemsMap = {};
    Object.keys(creditBookmarks).forEach((sourceInstitutionID) => {
      const groupedIndex = {};
      let currentIndex = 0;
      const creditItems = [];
      const creditItemsFromBookmarks =
        creditBookmarks[sourceInstitutionID] || [];
      const creditArrangements = creditArrangementsMap[sourceInstitutionID];
      if (_.isEmpty(creditArrangements)) {
        // console.info("No credit arrangements");
        return {};
      }
      creditItemsFromBookmarks.forEach((creditItemFromBookmarks) => {
        if (isArticulation(creditItemFromBookmarks.creditType)) {
          const articulations = creditArrangements.articulations || [];
          const foundArticulation = articulations.find(
            (articulation) =>
              articulation.ID === creditItemFromBookmarks.creditItemID,
          );
          // console.log("foundArticulation", foundArticulation);

          if (!foundArticulation) {
            console.info("No found articulation");
            return {};
          }
          //need to treat credentials as a whole - each credential should have the same issuer
          // foundArticulation.credentials.forEach((credential) => {
          const credentialIssuerID = get(
            foundArticulation.credentials[0],
            "data.credentialIssuerID",
          );
          // const courseName = get(credential, "data.unitName");
          let key = credentialIssuerID;
          // const key = credentialIssuerID + "-" + foundArticulation.ID;
          foundArticulation.credentials.forEach((credential) => {
            const courseName = get(credential, "data.unitName");
            key += "-" + courseName;
          });
          // console.log("key", key);
          // console.log("groupedIndex", groupedIndex);
          if (isNil(groupedIndex[key])) {
            // console.log("pushing to creditItems");
            groupedIndex[key] = currentIndex;
            creditItems.push({ grouped: [foundArticulation] });
          } else {
            currentIndex = currentIndex - 1;
            const creditItem = creditItems[groupedIndex[key]];
            creditItem.grouped.push(foundArticulation);
          }
          currentIndex++;
          // });
        } else {
          const precedents = creditArrangements.precedents || [];
          const foundPrecedent = precedents.find(
            (precedent) =>
              precedent.ID === creditItemFromBookmarks.creditItemID,
          );
          creditItems.push(foundPrecedent);
          currentIndex++;
        }
      });
      creditItemsMap[sourceInstitutionID] = creditItems;
    });
    return creditItemsMap;
  };
}

const mapStateToProps = (state) => {
  return {
    creditBookmarks: getCreditBookmarks(state),
    creditArrangementsMap: getCreditArrangementsMap(state),
    isFetchingSourceInstitutions: isFetchingSourceInstitutions(state),
    isFetchingCreditArrangements: isFetchingCreditArrangements(state),
    sourceInstitutionsMap: getSourceInstitutionsMap(state),
    applicationHasLoaded: isApplicationFinishedLoadingSelector(state),
  };
};

export default connect(mapStateToProps)(SavedCreditItems);
