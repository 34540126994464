import { call, put, takeLatest } from "@redux-saga/core/effects";
import { API } from "common/http/axiosClient";
import {
  fetchUrlsPending,
  // fetchUrlsError,
  fetchUrlsSuccess,
  FETCH_URLS,
} from "./urlAction";

function* getUrl(action) {
  yield put(fetchUrlsPending());
  try {
    const urls = yield call(API.getUrls);
    yield put(fetchUrlsSuccess(urls));
  } catch (err) {
    // yield put(fetchUrlsError(err));
    yield put(fetchUrlsSuccess([{}]));
  }
}

const urlSaga = [takeLatest(FETCH_URLS, getUrl)];

export default urlSaga;
