import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import classes from "./Header.module.scss";
import { Button, Badge, Drawer, Row, Col } from "antd";
import { Menu, X } from "react-feather";
import { selectUrls } from "store/custom-url/urlSelector";
import { connect } from "react-redux";
import { ConversionEvent } from "../../../hooks/analytics/events";
import { analytics } from "../../../hooks/analytics/analytics";
import _ from "lodash";
import GoToMainSite from "common/ui/button/GoToMainSite";
import i from "i18next";

const conversion = (e) => {
  const event = new ConversionEvent("start-application-button");
  analytics(event);
};

//TODO: include conversion attributes in form submission
const StartApplicationForm = ({
  endpoint,
  attributes = {},
  method = "POST",
}) => {
  const elements = _.keys(attributes).map((key) => (
    <input type="hidden" name={key} value={attributes[key]} />
  ));
  return (
    <form method={method} action={endpoint}>
      {_.isEmpty(elements) ? null : elements}
      <Button
        type="primary"
        htmlType="submit"
        className={`${classes.startApplicationBtn}`}
        id="application-btn"
        onClick={conversion}
      >
        Start an application
      </Button>
    </form>
  );
};

const Header = (props) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showNavigationDrawer = () => {
    setDrawerVisible(true);
  };

  const onNavigationDrawerClosed = () => {
    setDrawerVisible(false);
  };

  const whitelabel = props?.urls?.["application-btn"] || {
    url: "/",
    alt: "Home link",
  };

  const BrandLogo = (
    <div style={{ display: "flex" }}>
      <GoToMainSite>
        <img
          alt={`${i.t("institution")} logo`}
          src={`config/icons/${i.t("common.icons.logo")}`}
          height="56px"
        />
      </GoToMainSite>

      <div className={classes.brandingTextDivider}></div>
      <NavLink
        to="/home"
        className={classes.brandingTextContainer}
        id="home-link"
      >
        Credit
        <br /> calculator
      </NavLink>
    </div>
  );

  const drawerTitle = (
    <div>
      <span className={classes.drawerClose}>
        <X
          size={20}
          className={classes.navbarLinkIconStyle}
          onClick={onNavigationDrawerClosed}
        />
      </span>
      {BrandLogo}
    </div>
  );

  return (
    <>
      <Drawer
        id="nav-drawer"
        className={classes.navDrawer}
        title={drawerTitle}
        width="85%"
        headerStyle={{ borderBottom: "1px solid #C4CDD5" }}
        placement="left"
        closable={false}
        onClose={onNavigationDrawerClosed}
        visible={drawerVisible}
        key="left"
      >
        <ul className={classes.drawerNav}>
          <li>
            <Link to="/help" onClick={onNavigationDrawerClosed}>
              Help
            </Link>
          </li>
          <li>
            <StartApplicationForm endpoint={whitelabel.url} />
          </li>
        </ul>
      </Drawer>

      <Row id="header-top">
        <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
        <Col
          sm={{ span: 24 }}
          md={{ span: 22 }}
          lg={{ span: 18 }}
          className={classes.headerContainer}
        >
          <div className={classes.logoContainer}>
            <span id="navbar-menu-btn" className={classes.menuBtn}>
              <Menu
                size={20}
                className={classes.navbarLinkIconStyle}
                onClick={showNavigationDrawer}
              />
            </span>
            {BrandLogo}
          </div>
          <ul className={classes.navContainer}>
            <li className="nav-item">
              <Link to="/saved-credits" className={classes.navbarLink}>
                <Badge
                  size={"small"}
                  count={props.totalNumberOfCreditBookmarks}
                  offset={[-30, 0]}
                >
                  <img
                    alt={`heart filled`}
                    src={`config/icons/${i.t("common.icons.heart-filled")}`}
                    className={classes.navbarLinkIconStyle}
                  />
                </Badge>
                <div className={`${classes.navbarLinkText} navbar-link-text`}>
                  Saved
                </div>
              </Link>
            </li>
            <li className={`${classes.hidden} nav-item`}>
              <Link to="/help" className={classes.navbarLink}>
                <img
                  alt={`question mark`}
                  src={`config/icons/${i.t("common.icons.question")}`}
                  className={classes.navbarLinkIconStyle}
                />
                <div className={`${classes.navbarLinkText} navbar-link-text`}>
                  Help
                </div>
              </Link>
            </li>
            <li className={classes.hidden}>
              <StartApplicationForm endpoint={whitelabel.url} />
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

const mapStoreToProps = (store) => {
  return {
    urls: selectUrls(store),
  };
};

export default connect(mapStoreToProps)(Header);
