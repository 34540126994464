import _ from "lodash";

/**
 * Internal API - not to be invoked directly.
 * Dispatches events to the global analytics function defined by the client.
 */
const fire = async(event, args) => {
    // Not cached in case this is initialised asynchronously
    const func = window.analytics;
    if (!_.isFunction(func)) {
        // Analytics code isn't loaded or is not in use.
        return;
    }
    // Intentionally not awaiting as analytics code should not block application logic,
    // but rather happen in the background on a best effort basis.
    func(event, args);
};

/**
 * Internal API - safe to be invoked directly.
 * Hooked into the code base to allow external analytics platforms to optionally capture analytics events.
 * Executes asynchronously in the background without awaiting completion of the invocation.
 *
 * @param event - string
 * The typename of the event.
 *
 * @param args - object
 * The parameters/payload of the event.
 */
export const emit = (event, args) => {
    try {
        fire(event, args).catch(console.log);
    } catch (e) {
        console.log(`Exception invoking analytics: ${event}. Exception: ${e}`);
    }
};

/**
 * Public API - safe to be invoked directly - preferred method for sending analytics events.
 * @see emit
 *
 * @param event - AnalyticsEvent
 * The AnalyticsEvent object encapsulating the event information.
 */
export const analytics = (event) => emit(event.type(), event.args())