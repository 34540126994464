import React from "react";
import classes from "./CommonError.module.scss";
import i from "i18next";
class CommonError extends React.Component {
  render() {
    return (
      <div className={classes.commonErrorContainer}>
        <div className={classes.commonErrorContent}>
          <div className={classes.logo}>
            <img
              alt={`${i.t("institution")} logo`}
              src={`config/icons/${i.t("common.icons.logo")}`}
              className="logo"
              height="45px"
            />
          </div>
          <div className={classes.errorMessage}>
            Sorry, something went wrong.
          </div>
          <div className={classes.refreshMessage}>
            Try refreshing your page.
          </div>
        </div>
      </div>
    );
  }
}

export default CommonError;
