import React, { useState } from 'react';
import { axiosClient } from 'common/http/axiosClient';
import { Route } from 'react-router-dom';
import CommonError from 'pages/errors/CommonError';

const WithErrorHandler = (WrappedComponent) => {
  const HocComponent = (props) => {
    const [error, setError] = useState(false);

    axiosClient.interceptors.response.use(
      (response) => {
        setError(false);
        return response;
      },
      (error) => {
        setError(true);
        return Promise.reject(error);
      }
    );

    return (
      <>
        {error ? (
          <Route component={CommonError} />
        ) : (
          <WrappedComponent {...props} />
        )}
      </>
    );
  };
  return HocComponent;
};

export default WithErrorHandler;
