import store from "store";

export const getLocalStorageSelectedInstitution = () => {
  return store.get("selected-source-institution") || {};
};

export const setLocalStorageSelectedInstitution = (institution) => {
  store.set("selected-source-institution", institution);
};

export const getLocalStorageRecentInstitutionIds = () => {
  return store.get("recent-institution-ids") || [];
};

export const setLocalStorageRecentInstitutionIds = (ids) => {
  store.set("recent-institution-ids", ids);
};

export const getLocalStorageCreditBookmarks = () => {
  return store.get("credit-bookmarks") || {};
};

export const setLocalStorageCreditBookmarks = (creditBookmarks) => {
  store.set("credit-bookmarks", creditBookmarks);
};

// const resolveStorageKey = (key) => {
//   return `${process.env.IMPLEMENTATION}_${key}`;
// };
