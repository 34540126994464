import React from "react";
import { ReactComponent as SearchIllustration } from "common/ui/icons/search-illustration.svg";
import classes from "./ResultNotFound.module.scss";
import ContactButton from "common/ui/button/ContactButton";

const ResultNotFound = () => {
  return (
    <div className={`${classes.resultNotFoundContainer} not-found-container`}>
      <SearchIllustration className={classes.searchIllustrationIcon} />
      <div className={`${classes.notFoundText} noresult-title`}>
        No results found?
      </div>
      <div className={`${classes.notFoundDescription} noresult-body`}>
        <p>
          Use the tabs above to see more results or try different search terms.
        </p>
        <p>
          If you still can’t find any results, contact us to discuss your credit
          options.
        </p>
        <ContactButton />
      </div>
    </div>
  );
};

export default ResultNotFound;
