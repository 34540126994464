import React from "react";
import { Row, Col, Affix } from "antd";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "pages/Home";
import Error404 from "pages/errors/Error404";
import classes from "./App.module.scss";
import Header from "components/layout/header/Header";
import CreditDetails from "pages/CreditDetails";
import SavedCredits from "pages/SavedCredits";
import { fetchCreditBookmarks } from "store/credit-bookmark/creditBookmarkAction";
import { getTotalNumberOfCreditBookmarks } from "store/credit-bookmark/creditBookmarkSelector";
import WithErrorHandler from "hoc/error-handler/WithErrorHandler";
import "styles/global.scss";
import Help from "pages/Help";
import WithWindowDimensions from "hoc/window-dimensions/WithWindowDimensions";
import { fetchUrls } from "store/custom-url/urlAction";
import GoToMainSite from "common/ui/button/GoToMainSite";
import { fetchExternalData } from "store/external-data/externalDataAction";
import { HashLink as Link } from "react-router-hash-link";

class App extends React.Component {
  componentDidMount() {
    this.props.onFetchCreditBookmarks();
    this.props.onFetchCustomUrls();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.urlsFetching && !this.props.urlsFetching) {
      this.props.onFetchExternalData();
    }
  }

  render() {
    const isMobile = this.props.width <= 767;
    /// if it is not fetching AND urls are not empty

    // let paddingTop = 0;
    // const customHeader = document.getElementById("custom-header");
    // if (!isEmpty(customHeader)) {
    //   paddingTop = customHeader.offsetHeight;
    // }

    return (
      <div id="overload" className={classes.layoutRoot}>
        <Link className={classes.skipToContent} to="#main-content">
          Skip to content
        </Link>
        {/*
        select the theme if needed */}
        {/* <React.Suspense fallback={<></>}>
          {process.env.IMPLEMENTATION && <Theme />}
        </React.Suspense> */}

        <Affix>
          <div className={classes.headerContainer} id="custom-header">
            <Row className={classes.ribbonContainer} id="ribbon-container">
              <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
              <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
                <div className={classes.ribbon}>
                  <GoToMainSite>Go to main site</GoToMainSite>
                </div>
              </Col>
            </Row>
            <Header
              totalNumberOfCreditBookmarks={
                this.props.totalNumberOfCreditBookmarks
              }
            />
          </div>
        </Affix>

        <div
          className={classes.contentContainer}
          style={
            isMobile
              ? {
                  paddingTop:
                    document.getElementById("custom-header")?.offsetHeight,
                }
              : {}
          }
        >
          <div id="main-content" className={classes.content}>
            <Switch>
              <Route
                path="/"
                exact={true}
                render={(props) => <Redirect to="/home" />}
              />
              <Route
                path="/home"
                exact={true}
                render={(props) => <Home {...props} isMobile={isMobile} />}
              />
              <Route
                path="/saved-credits"
                exact={true}
                render={(props) => (
                  <SavedCredits {...props} isMobile={isMobile} />
                )}
              />
              <Route
                path="/details/articulation/:creditItemID"
                exact={true}
                render={(props) => (
                  <CreditDetails {...props} isMobile={isMobile} />
                )}
              />
              <Route
                path="/details/precedent/:creditItemID"
                exact={true}
                render={(props) => (
                  <CreditDetails {...props} isMobile={isMobile} />
                )}
              />
              <Route path="/help" exact={true} component={Help} />
              <Route component={Error404} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalNumberOfCreditBookmarks: getTotalNumberOfCreditBookmarks(state),
    urlsFetching: state.urlReducer.fetching,
    credits: state.creditArrangementState.creditArrangements,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchExternalData: () => dispatch(fetchExternalData()),
    onFetchCreditBookmarks: () => dispatch(fetchCreditBookmarks()),
    onFetchCustomUrls: () => dispatch(fetchUrls()),
  };
};

export default WithErrorHandler(
  WithWindowDimensions(connect(mapStateToProps, mapDispatchToProps)(App)),
);
