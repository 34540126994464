export const FETCH_METADATA = 'FETCH_METADATA';
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS';
export const FETCH_METADATA_ERROR = 'FETCH_METADATA_ERROR';

export const fetchMetadata = () => {
  return {
    type: FETCH_METADATA,
  };
};

export const fetchMetadataSuccess = (metadata) => {
  return {
    type: FETCH_METADATA_SUCCESS,
    metadata,
  };
};

export const fetchMetadataError = () => {
  return {
    type: FETCH_METADATA_ERROR,
  };
};
