export const transformToEntityMap = (array, field) => {
  const map = {};
  (array || []).forEach((element) => {
    const fieldValue = element[field];
    if (fieldValue && !map[fieldValue]) {
      map[fieldValue] = element;
    }
  });
  return map;
};
