export const FETCH_CREDIT_ARRANGEMENTS = "FETCH_CREDIT_ARRANGEMENTS";
export const FETCH_CREDIT_ARRANGEMENTS_SUCCESS =
  "FETCH_CREDIT_ARRANGEMENTS_SUCCESS";
export const FETCH_CREDIT_ARRANGEMENTS_ERROR =
  "FETCH_CREDIT_ARRANGEMENTS_ERROR";

export const fetchCreditArrangements = () => {
  return {
    type: FETCH_CREDIT_ARRANGEMENTS,
  };
};

export const fetchCreditArrangementsSuccess = (creditArrangements) => {
  return {
    type: FETCH_CREDIT_ARRANGEMENTS_SUCCESS,
    creditArrangements,
  };
};

export const fetchCreditArrangementsError = () => {
  return {
    type: FETCH_CREDIT_ARRANGEMENTS_ERROR,
  };
};
