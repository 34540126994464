import { version } from "../package.json";
import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "store/store";
import { HashRouter } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
// import AuthWrapper from "auth/AuthWrapper";

import i18next from "i18next";
import Backend from "i18next-xhr-backend";

import "./index.scss";

const app = (
  <Provider store={store}>
    <div hidden>{`version: ${version}`}</div>
    <HashRouter>
      <ScrollToTop>
        {/* <AuthWrapper> */}
        <App />
        {/* </AuthWrapper> */}
      </ScrollToTop>
    </HashRouter>
  </Provider>
);

// const institutions = ["uom", "vu"];
i18next.use(Backend).init(
  {
    lng: "uom", // change this to change the translation used
    fallbackLng: "uom", // prevent loading other locales than 'en'
    debug: true,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/config/translation.json`,
    },
  },
  function (err, t) {
    ReactDOM.render(app, document.getElementById("root"));
  },
);
