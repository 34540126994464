import React from "react";
import { Card as AntCard } from "antd";

const Card = (props) => {
  const customHeadStyle = getIsDefault(props)
    ? headStyle
    : { ...headStyle, backgroundColor: "#F8F9FA" };

  const bodyStyle = getIsZeroPadding(props) ? { padding: 0 } : {};

  return (
    <AntCard
      title={props.title}
      style={cardStyle}
      headStyle={customHeadStyle}
      bodyStyle={bodyStyle}
      className={props.className}
    >
      {props.children}
    </AntCard>
  );
};

const getIsDefault = (props) => {
  return props.default !== undefined ? props.default : false;
};

const getIsZeroPadding = (props) => {
  return props.zeroPadding !== undefined ? props.zeroPadding : false;
};

export default Card;

const borderColor = "#C4CDD5";
const bordrerRadius = 6;

const cardStyle = {
  borderRadius: bordrerRadius,
  borderColor: borderColor,
  padding: 0,
};

const headStyle = {
  borderTopRightRadius: 6,
  borderTopLeftRadius: 6,
  borderBottomColor: borderColor,
};
