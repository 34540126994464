import React, { Fragment } from "react";
import { connect } from "react-redux";
import i from "i18next";
import { Button, Row, Col } from "antd";
import { Heart, ArrowDown, ArrowRight } from "react-feather";
import Card from "common/ui/card/Card";
import {
  getArticulationByID,
  getPrecedentByID,
  isFetchingCreditArrangements,
} from "store/credit-arrangement/creditArrangementSelector";
import { get } from "lodash";
import {
  isFetchingSourceInstitutions,
  getSourceInstitutionById,
} from "store/source-institution/sourceInstitutionSelector";
import {
  getTotalCreditPoints,
  isCreditItemExistsInBookmarks,
} from "utils/creditUtils";
import SubjectDetailList from "../SubjectDetailList";
import classes from "./CreditItemDetails.module.scss";
import {
  addCreditToBookmark,
  removeCreditFromBookmark,
} from "store/credit-bookmark/creditBookmarkAction";
import { getCreditBookmarks } from "store/credit-bookmark/creditBookmarkSelector";
import { CREDIT_TYPES, SUBJECT_TYPES } from "common/model/creditType";
import BackToResultButton from "common/ui/button/BackToResultButton";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { getMetadataTimestamp } from "store/metadata/metadataSelector";
import FullPageSpinner from "common/ui/loading/FullPageSpinner";
import { isApplicationFinishedLoadingSelector } from "utils/generalUtils";

class CreditItemDetails extends React.Component {
  render() {
    // if (
    //   this.props.isFetchingSourceInstitutions ||
    //   this.props.isFetchingCreditArrangements
    // ) {
    //   return (
    //     <LoadingOutlined
    //       style={{ fontSize: 50 }}
    //       className="loading-outlined"
    //     />
    //   );
    // }

    const {
      creditItem,
      sourceInstitution,
      isArticulation,
      creditBookmarks,
      isMobile,
      timestamp,
    } = this.props;

    const totalCreditPoints = getTotalCreditPoints(creditItem);
    const creditType = isArticulation
      ? CREDIT_TYPES.ARTICULATION
      : CREDIT_TYPES.PRECEDENT;

    const isExistingCreditItemInBookmarks = isCreditItemExistsInBookmarks(
      creditBookmarks,
      sourceInstitution && sourceInstitution.ID,
      creditItem && creditItem.ID,
      creditType,
    );

    const FavouriteButton = () => {
      if (!this.props.applicationHasLoaded) {
        return <></>;
      }
      return (
        <span className={classes.saveCreditBtnContainer}>
          <Button
            type="text"
            className={`${classes.saveCreditBtn} save-credit-button`}
            onClick={() =>
              !isExistingCreditItemInBookmarks
                ? this.addCreditItemToBookmarks(
                    sourceInstitution?.ID,
                    creditItem?.ID,
                    creditType,
                    timestamp,
                  )
                : this.removeCreditItemFromBookmarks(
                    sourceInstitution?.ID,
                    creditItem?.ID,
                    creditType,
                  )
            }
          >
            <Heart
              size={18}
              className={
                isExistingCreditItemInBookmarks
                  ? ["heart-icon-filled", classes.iconStyle].join(" ")
                  : [classes.iconStyle, classes.iconStyleOutline].join(" ")
              }
            />
            &nbsp;&nbsp;
            {isExistingCreditItemInBookmarks
              ? isMobile
                ? "Saved"
                : "Remove from saved"
              : "Save credit"}
          </Button>
        </span>
      );
    };

    return (
      <div className={classes.creditItemDetailsContainer}>
        <Row id="details__banner-bottom">
          <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
          <Col
            sm={{ span: 24 }}
            md={{ span: 22 }}
            lg={{ span: 18 }}
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <div className={classes.navContainer}>
                <BackToResultButton isMobile={isMobile} />
                <FavouriteButton />
              </div>
              <div className={`${classes.creditPointsContainer} title-section`}>
                <div className={classes.creditPointstitle}>You may receive</div>
                <div className={`${classes.creditPoints} credit-points`}>
                  {totalCreditPoints} credit points
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div id="details__banner-top" className={`${classes.hidden}`}>
          <Row className={`title-section`}>
            <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
            <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
              <div className="page-title">Credit detail</div>
              <p>You may receive {totalCreditPoints} credit points</p>
              <p>{i.t("details.description")}</p>
            </Col>
          </Row>
          {/*Make changes here */}
          <Row>
            <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
            <Col
              sm={{ span: 24 }}
              md={{ span: 22 }}
              lg={{ span: 18 }}
              style={{ width: "100%" }}
            >
              <div className={classes.navContainer}>
                <BackToResultButton isMobile={isMobile} />
                <FavouriteButton />
              </div>
              <div
                id="details-optional-title"
                className={`${classes.hidden} section-title`}
              >
                {this.props.isArticulation
                  ? i.t("details.optionalArticulationsTitle")
                  : i.t("details.optionalPrecedentsTitle")}
              </div>
            </Col>
          </Row>
        </div>

        {!this.props.applicationHasLoaded ? (
          <FullPageSpinner />
        ) : (
          <>
            <Row>
              <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
              <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
                <div className={classes.creditDetailsContainer}>
                  {isArticulation ? (
                    isMobile ? (
                      <div
                        className={`${classes.mobileCreditDetails} mobile-credit-details`}
                      >
                        <ArticulationHeading
                          creditItem={creditItem}
                          sourceInstitution={sourceInstitution}
                        />
                        <CreditBlockDetails
                          creditItem={creditItem}
                          totalCreditPoints={totalCreditPoints}
                          sourceInstitution={sourceInstitution}
                          isArticulation={isArticulation}
                        />
                      </div>
                    ) : (
                      <div style={{ padding: 16 }}>
                        <Card
                          default
                          title={
                            <ArticulationHeading
                              creditItem={creditItem}
                              sourceInstitution={sourceInstitution}
                            />
                          }
                        >
                          <CreditBlockDetails
                            creditItem={creditItem}
                            totalCreditPoints={totalCreditPoints}
                            sourceInstitution={sourceInstitution}
                            isArticulation={isArticulation}
                          />
                        </Card>
                      </div>
                    )
                  ) : (
                    <div style={isMobile ? { padding: null } : { padding: 16 }}>
                      <CreditBlockDetails
                        creditItem={creditItem}
                        totalCreditPoints={totalCreditPoints}
                        sourceInstitution={sourceInstitution}
                        isArticulation={isArticulation}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
              <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
                <div
                  className={`${classes.conditionContainer} details-condition-container`}
                >
                  <Row>
                    <Col
                      span={18}
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      className={classes.conditionCol}
                    >
                      <div
                        className={`${classes.conditionTitle} section-title`}
                      >
                        {i.t("details.conditions.title")}
                      </div>
                      <div>
                        {i.t("details.conditions.description")}
                        <ul className={classes.conditionList}>
                          {i
                            .t("details.conditions.list", {
                              returnObjects: true,
                            })
                            .map((li) => (
                              <li key={li}>{li}</li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                    <Col span={6} xs={24} sm={24} md={6} lg={6}>
                      {getValidDatesMarkup(creditItem)}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }

  addCreditItemToBookmarks = (
    sourceInstitutionID,
    creditItemID,
    creditType,
    timestamp,
  ) => {
    this.props.onCreditItemAddedToBookmarks(
      sourceInstitutionID,
      creditItemID,
      creditType,
      timestamp,
    );
  };

  removeCreditItemFromBookmarks = (
    sourceInstitutionID,
    creditItemID,
    creditType,
  ) => {
    this.props.onCreditItemRemovedFromBookmarks(
      sourceInstitutionID,
      creditItemID,
      creditType,
    );
  };
}

const getValidDatesMarkup = (itemBlock) => {
  const effectiveStart = get(
    itemBlock,
    "arrangements[0].effectiveStartDate",
    get(itemBlock, "effectiveStartDate", null),
  );
  const effectiveEnd = get(
    itemBlock,
    "arrangements[0].effectiveEndDate",
    get(itemBlock, "effectiveEndDate", null),
  );

  let validDateString = i.t("details.validDates.description");
  if (effectiveEnd) {
    validDateString += ` between ${dayjs(effectiveStart).format(
      "DD MMM YYYY",
    )} and ${dayjs(effectiveEnd).format("DD MMM YYYY")}`;
  } else {
    validDateString += ` from ${dayjs(effectiveStart).format(
      "DD MMM YYYY",
    )} onwards`;
  }

  // const effectiveFromString = dayjs(effectiveFrom).format("DD MMM YYYY");
  // const effectiveEndString = dayjs(effectiveEnd).format("DD MMM YYYY");
  return (
    <Fragment>
      <div className={`${classes.conditionTitle} section-title`}>
        {i.t("details.validDates.title")}
      </div>
      <div>{validDateString}</div>
    </Fragment>
  );
};

const getTargetSubjectContentMarkup = (itemBlock) => {
  const displayName = get(itemBlock, "course.name", "");
  const courseUrl = get(itemBlock, "course.url", null);
  const displayNameMarkup = <span>{displayName}</span>;
  return (
    <div className={classes.articulationMainHeading}>
      {courseUrl ? (
        <a
          href={courseUrl}
          target="_blank"
          rel="noreferrer"
          className={classes.targetSubjectExternalLink}
        >
          {displayNameMarkup}
          <img
            src={`config/icons/${i.t("common.icons.external-link")}`}
            alt="external tab"
            height={20}
          />
        </a>
      ) : (
        displayNameMarkup
      )}
    </div>
  );
};

const getCricosCodeMarkup = (itemBlock) => {
  const cricosCodes = get(itemBlock, "course.cricosCodes", []);
  if (cricosCodes.length === 0) {
    return null;
  }
  const cricosString = `CRICOS Code${
    cricosCodes.length > 1 ? "s" : ""
  }: ${itemBlock.course.cricosCodes.join(", ")}`;
  return (
    <div className={classes.articulationHeadingBottomDescription}>
      {cricosString}
    </div>
  );
};

const CreditBlockDetails = (props) => {
  const { creditItem, totalCreditPoints, sourceInstitution, isArticulation } =
    props;

  const sourceDetails = get(creditItem, "credentials", []).map(
    (credential) => ({
      ID: credential.data.unitCode,
      name: credential.data.unitName,
    }),
  );
  const targetDetails = isArticulation
    ? get(creditItem, "arrangements[0].subjects", [])
    : get(creditItem, "subjects", []);
  const eduProviderName = i.t("institution");
  return (
    <div className={classes.creditBlockDetailsContainer}>
      <Card
        title={
          isArticulation && sourceDetails.length === 1 ? null : (
            <CreditBlockHeading
              creditItem={creditItem}
              sourceDetails={sourceDetails}
              sourceInstitution={sourceInstitution}
              isArticulation={isArticulation}
            />
          )
        }
      >
        {
          <CreditBlockBody
            targetDetails={targetDetails}
            totalCreditPoints={totalCreditPoints}
            eduProviderName={eduProviderName}
            creditItem={creditItem}
          />
        }
      </Card>
    </div>
  );
};

const CreditBlockBody = (props) => {
  const { targetDetails, totalCreditPoints, eduProviderName } = props;

  return (
    <Row>
      <Col span={4} xs={24} sm={24} md={4} lg={4}>
        <img
          src={`config/icons/${i.t("common.icons.logo-small")}`}
          alt={`${i.t("institution")} logo`}
          height="40px"
          className={`${classes.creditBlockLogo} logo-small-icon`}
        />
      </Col>

      <Col span={16} xs={24} sm={24} md={16} lg={16}>
        <div>
          <SubjectDetailList subjects={targetDetails} />
        </div>
        <div className={`${classes.bottomDescription} bottom-description`}>
          {eduProviderName}
        </div>
      </Col>

      <Col
        span={4}
        xs={24}
        sm={24}
        md={4}
        lg={4}
        className={classes.creditBlockPointsCol}
      >
        <div>{totalCreditPoints}</div>
        <div className={`${classes.bottomDescription} bottom-description`}>
          Credit points
        </div>
      </Col>
    </Row>
  );
};

const CreditBlockHeading = (props) => {
  const { creditItem, sourceDetails, sourceInstitution, isArticulation } =
    props;
  return (
    <Row>
      <Col span={20} xs={24} sm={24} md={20} lg={20}>
        <div>
          <SubjectDetailList subjects={sourceDetails} />
        </div>
        <div className={`${classes.bottomDescription} bottom-description`}>
          {get(sourceInstitution, "displayName", "")}
        </div>
      </Col>

      {!isArticulation &&
        creditItem.yearCompletedMin &&
        creditItem.yearCompletedMax && (
          <Col
            span={4}
            xs={24}
            sm={24}
            md={4}
            lg={4}
            className={classes.creditBlockHeadingCompletionCol}
          >
            <div className={classes.topHeading}>
              {`${creditItem.yearCompletedMin} - ${creditItem.yearCompletedMax}`}
            </div>
            <div className={`${classes.bottomDescription} bottom-description`}>
              Completed
            </div>
          </Col>
        )}
    </Row>
  );
};

const OneOrMoreCredentialNames = ({ itemBlock }) => {
  if (isEmpty(itemBlock)) {
    return null;
  }
  if (itemBlock.credentials.length === 1) {
    const displayName = itemBlock.credentials[0].data.unitName;
    return (
      <span className={`${classes.topTitle} top-title`}>{displayName}</span>
    );
  } else {
    return (
      <div>
        {itemBlock.credentials.map((cred, i) => {
          const displayName = cred.data.unitName;
          const last = i === itemBlock.credentials.length - 1;
          return (
            <div key={`${i}-${displayName}`}>
              <div className={`${classes.topTitle} top-title`}>
                {displayName}
              </div>
              {last ? null : (
                <div
                  style={{
                    fontSize: 12,
                    color: "rgb(99, 115, 129)",
                  }}
                >
                  AND
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
};
const ArticulationHeading = (props) => {
  const { creditItem, sourceInstitution } = props;

  return (
    <div className={classes.articulationHeadingContainer}>
      <Row>
        <Col span={10} xs={24} sm={24} md={10} lg={10}>
          <div className={classes.articulationMainHeading}>
            <OneOrMoreCredentialNames itemBlock={creditItem} />
          </div>
          <div className={classes.articulationHeadingBottomDescription}>
            {get(sourceInstitution, "displayName", "")}
          </div>
          {getCohortContentMarkup(creditItem, SUBJECT_TYPES.SOURCE)}
        </Col>

        <Col
          span={4}
          xs={24}
          sm={24}
          md={4}
          lg={4}
          className={classes.arrowIconCol}
        >
          <div className={classes.arrowRight}>
            <div className={classes.arrowIconContainer}>
              <ArrowRight size={28} className={classes.arrowIcon} />
            </div>
          </div>

          <div className={classes.arrowDown}>
            <div className={classes.arrowIconContainer}>
              <ArrowDown size={18} className={classes.arrowIcon} />
            </div>
          </div>
        </Col>

        <Col span={10} xs={24} sm={24} md={10} lg={10}>
          {getTargetSubjectContentMarkup(creditItem)}
          <div className={classes.articulationHeadingBottomDescription}>
            {i.t("institution")}
          </div>
          {getCourseCodeMarkup(creditItem)}
          {getCricosCodeMarkup(creditItem)}
          {getCohortContentMarkup(creditItem, SUBJECT_TYPES.TARGET)}
          {getArticulationDetailsMarkupContent(creditItem)}
        </Col>
      </Row>
    </div>
  );
};

const getCourseCodeMarkup = (creditItem) => {
  return (
    <div
      className={classes.articulationHeadingBottomDescription}
    >{`Course Code: ${creditItem?.course?.ID}`}</div>
  );
};

const getCohortContentMarkup = (itemBlock, subjectType) => {
  const cohorts =
    subjectType === SUBJECT_TYPES.SOURCE
      ? get(itemBlock, "credentials[0].data.cohorts", [])
      : get(itemBlock, "course.cohorts", []);
  if (cohorts.length === 0) {
    return null;
  }
  return (
    <div className={`${classes.cohortRow} cohort-row`}>
      <span>For:</span>
      {cohorts.map((cohort) => {
        const label = i.t(`common.cohorts.${cohort}.label`);
        const icon = i.t(`common.cohorts.${cohort}.icon`);
        const alt = i.t(`common.cohorts.${cohort}.alt`);
        const filter = i.t(`common.cohorts.${cohort}.filter`);
        if (!(label || icon || alt || filter)) {
          return null;
        }
        return (
          <div key={cohort} className={classes.cohortItem}>
            <img
              src={`config/icons/${i.t(`common.icons.${icon}`)}`}
              alt={alt}
              width={16}
              style={{ filter: filter }}
            />
            {label}
          </div>
        );
      })}
    </div>
  );
};

const getArticulationDetailsMarkupContent = (creditItem) => {
  const creditPoints = getTotalCreditPoints(creditItem);
  let additionalDetailsLabel;
  if (
    creditItem.guaranteedEntry &&
    !creditItem.admissionCriteria &&
    creditPoints === 0
  ) {
    additionalDetailsLabel = "Guaranteed entry only";
  } else if (!creditItem.guaranteedEntry && creditItem.admissionCriteria) {
    additionalDetailsLabel = "Admission criteria applies";
  }
  if (!additionalDetailsLabel) {
    return null;
  }

  return (
    <div className={classes.additionalDetailsItem}>
      <img
        src={`config/icons/${i.t("common.icons.info-filled")}`}
        alt={"information"}
        width={16}
        className={classes.infoFilledIcon}
      />
      {additionalDetailsLabel}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const creditItemID = props.creditItemID;

  const creditItem = props.isArticulation
    ? getArticulationByID(creditItemID)(state)
    : getPrecedentByID(creditItemID)(state);
  const credentialIssuerID = get(
    creditItem,
    "credentials[0].data.credentialIssuerID",
  );

  return {
    creditItem,
    isFetchingSourceInstitutions: isFetchingSourceInstitutions(state),
    isFetchingCreditArrangements: isFetchingCreditArrangements(state),
    sourceInstitution: getSourceInstitutionById(credentialIssuerID)(state),
    creditBookmarks: getCreditBookmarks(state),
    timestamp: getMetadataTimestamp(state),
    applicationHasLoaded: isApplicationFinishedLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreditItemAddedToBookmarks: (
      sourceInstitutionID,
      creditItemID,
      creditType,
      timestamp,
    ) =>
      dispatch(
        addCreditToBookmark(
          sourceInstitutionID,
          creditItemID,
          creditType,
          timestamp,
        ),
      ),
    onCreditItemRemovedFromBookmarks: (
      sourceInstitutionID,
      creditItemID,
      creditType,
    ) =>
      dispatch(
        removeCreditFromBookmark(sourceInstitutionID, creditItemID, creditType),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditItemDetails);
