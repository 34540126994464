export const FETCH_CREDIT_BOOKMARKS = 'FETCH_CREDIT_BOOKMARK';
export const ADD_CREDIT_TO_BOOKMARK_ACTION = 'ADD_CREDIT_TO_BOOKMARK_ACTION';
export const REMOVE_CREDIT_FROM_BOOKMARK_ACTION =
  'REMOVE_CREDIT_FROM_BOOKMARK_ACTION';

export const fetchCreditBookmarks = () => {
  return {
    type: FETCH_CREDIT_BOOKMARKS,
  };
};

export const addCreditToBookmark = (
  sourceInstitutionID,
  creditItemID,
  creditType,
  timestamp,
) => {
  return {
    type: ADD_CREDIT_TO_BOOKMARK_ACTION,
    sourceInstitutionID,
    creditItemID,
    creditType,
    timestamp,
  };
};

export const removeCreditFromBookmark = (
  sourceInstitutionID,
  creditItemID,
  creditType,
) => {
  return {
    type: REMOVE_CREDIT_FROM_BOOKMARK_ACTION,
    sourceInstitutionID,
    creditItemID,
    creditType,
  };
};
