import {
  FETCH_METADATA,
  FETCH_METADATA_SUCCESS,
  FETCH_METADATA_ERROR,
} from './metadataAction';

const initialState = {
  metadata: {},
  fetching: false,
  error: false,
};

const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METADATA: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case FETCH_METADATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        metadata: action.metadata,
      };
    }
    case FETCH_METADATA_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default metadataReducer;
