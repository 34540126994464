import axios from "axios";
import { API_BASE_URL } from "common/api/apiRoutes";

export const axiosClient = axios.create({ baseURL: API_BASE_URL });

const getData = async (url) => {
  if (!url) {
    console.error("No URL Provided!");
  }
  try {
    const response = await axiosClient.get(url);
    const parsedData = response.data.resp.fileData;
    return parsedData;
  } catch (err) {
    console.error(
      "error while fetching or parsing data. Reverting to fallback",
    );
  }
};

export const API = {
  getExternalData: async (url) => {
    const data = await getData(url);
    return {
      metadata: data.metadata,
      sourceInstitutions: data.sourceInstitutions,
      precedents: data.precedents,
      articulations: data.articulations,
    };
  },

  getUrls: async () => {
    const response = await axiosClient.get("config/urls.json");
    return response.data;
  },
};
