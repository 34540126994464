export const FETCH_SOURCE_INSTITUTIONS = "FETCH_SOURCE_INSTITUTIONS";
export const FETCH_SOURCE_INSTITUTIONS_SUCCESS =
  "FETCH_SOURCE_INSTITUTIONS_SUCCESS";
export const FETCH_SOURCE_INSTITUTIONS_ERROR =
  "FETCH_SOURCE_INSTITUTIONS_ERROR";
export const SET_SELECTED_INSTITUTION = "SET_SELECTED_INSTITUTION";
export const SET_RECENT_INSTITUTIONS = "SET_RECENT_INSTITUTIONS";

export const fetchSourceInstitutions = () => {
  return {
    type: FETCH_SOURCE_INSTITUTIONS,
  };
};

export const fetchSourceInstitutionsSuccess = (sourceInstitutions) => {
  return {
    type: FETCH_SOURCE_INSTITUTIONS_SUCCESS,
    sourceInstitutions,
  };
};

export const fetchSourceInstitutionsError = () => {
  return {
    type: FETCH_SOURCE_INSTITUTIONS_ERROR,
  };
};

export const setSelectedInstitution = (institutionID) => {
  return {
    type: SET_SELECTED_INSTITUTION,
    institutionID,
  };
};

export const setRecentInstitutions = (institutionIDs) => {
  return {
    type: SET_RECENT_INSTITUTIONS,
    institutionIDs,
  };
};
