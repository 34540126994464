import React from "react";
import { Collapse, Row, Col } from "antd";
import i from "i18next";
import { ChevronDown, ChevronUp } from "react-feather";
import BackToResultButton from "common/ui/button/BackToResultButton";
import classes from "./HelpInstruction.module.scss";

const { Panel } = Collapse;

const HelpInstruction = () => {
  const expandIconPosition = "right";

  const expandIcon = ({ isActive }) => {
    return isActive ? (
      <ChevronUp size={24} color="#637381" />
    ) : (
      <ChevronDown size={24} color="#637381" />
    );
  };

  const sections = i.t("help.sections", { returnObjects: true });

  const description = i.t("help.description", { returnObjects: true }) || [];

  return (
    <>
      <Row className="title-section">
        <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
        <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
          <div className={`${classes.helpMainTitle} page-title`}>
            {i.t("help.title")}
          </div>
          {description.map((d, i) => (
            <p key={i}>{d}</p>
          ))}
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
        <Col
          sm={{ span: 24 }}
          md={{ span: 22 }}
          lg={{ span: 18 }}
          style={{ width: "100%" }}
        >
          <div className={classes.navContainer}>
            <BackToResultButton isMobile={false} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
        <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
          <div className={`${classes.helpContainer} help-container`}>
            {sections.map((section) => {
              return (
                <div key={section.key} className={classes.helpContent}>
                  <div
                    className={`${classes.helpContentTitle} help-content-title`}
                  >
                    {section.heading}
                  </div>
                  <div className={`help-content`}>
                    {section.content.map((content) => (
                      <Collapse
                        expandIconPosition={expandIconPosition}
                        bordered={false}
                        expandIcon={expandIcon}
                        key={content.key}
                      >
                        <Panel
                          header={
                            <span
                              className={`${classes.collapsePanelHeader} collapse-panel-header`}
                            >
                              {content.question}
                            </span>
                          }
                        >
                          <div
                            className={`${classes.collapsePanelContent} collapse-panel-content`}
                          >
                            {content.answer}
                          </div>
                        </Panel>
                      </Collapse>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HelpInstruction;
