import { combineReducers } from 'redux';
import sourceInstitutionReducer from './source-institution/sourceInstitutionReducer';
import creditArrangementReducer from './credit-arrangement/creditArrangementReducer';
import creditBookmarkReducer from './credit-bookmark/creditBookmarkReducer';
import searchReducer from './search/searchReducer';
import { urlReducer } from './custom-url/urlReducer';
import metadataReducer from 'store/metadata/metadataReducer';

const rootReducer = combineReducers({
  metadataState: metadataReducer,
  sourceInstitutionState: sourceInstitutionReducer,
  creditArrangementState: creditArrangementReducer,
  creditBookmarkState: creditBookmarkReducer,
  searchState: searchReducer,
  urlReducer: urlReducer,
});

export default rootReducer;
