export const CREDIT_TYPES = {
  ARTICULATION: "ARTICULATION",
  PRECEDENT: "PRECEDENT",
};

export const ARTICULATION_TYPES = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  OFFSHORE: "OFFSHORE",
};

export const ARTICULATION_SUBTYPES = {
  DOMESTIC: "DOMESTIC",
  INTERNATIONAL: "INTERNATIONAL",
};

export const SUBJECT_TYPES = {
  SOURCE: "SOURCE",
  TARGET: "TARGET",
};
