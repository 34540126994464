import React from "react";
// import { Link } from "react-router-dom";
import { Heart } from "react-feather";
import classes from "./NoSavedCredit.module.scss";

const NoSavedCredit = () => {
  return (
    <>
      <div className={`${classes.title} page-title`}>No saved credit</div>
      <div className={classes.description}>
        To save a match for later, press the{" "}
        <Heart size={16} className={classes.heartIcon} /> icon on a credit
        match.
      </div>
      <div className={classes.homeLink}>
        {/* <Link to="/home">Keep searching for credit</Link> */}
      </div>
    </>
  );
};

export default NoSavedCredit;
