import React from "react";
import i from "i18next";
import { Helmet } from "react-helmet";
import CreditCalculator from "components/CreditCalculator";

class Home extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{`${i.t("appName")}`}</title>
        </Helmet>
        <CreditCalculator isMobile={this.props.isMobile} />
      </>
    );
  }
}

export default Home;
