import {
  FETCH_SOURCE_INSTITUTIONS,
  FETCH_SOURCE_INSTITUTIONS_SUCCESS,
  FETCH_SOURCE_INSTITUTIONS_ERROR,
  SET_SELECTED_INSTITUTION,
  SET_RECENT_INSTITUTIONS,
} from "./sourceInstitutionAction";
import { cloneDeep } from "lodash";
import {
  getLocalStorageSelectedInstitution,
  getLocalStorageRecentInstitutionIds,
  setLocalStorageSelectedInstitution,
  setLocalStorageRecentInstitutionIds,
} from "utils/localStorage";

const initialState = {
  sourceInstitutions: {},
  selectedInstitution: {},
  recentInstitutionIDs: [],
  fetching: false,
  error: false,
};

const sourceInstitutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOURCE_INSTITUTIONS: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }

    case FETCH_SOURCE_INSTITUTIONS_SUCCESS: {
      const { sourceInstitutions } = action;
      const selectedInstitution = getLocalStorageSelectedInstitution();
      let filteredSelectedInstitution = selectedInstitution;
      const recentInstitutionIDs = getLocalStorageRecentInstitutionIds();
      if (!sourceInstitutions.hasOwnProperty(selectedInstitution.ID)) {
        filteredSelectedInstitution = {};
      }
      let filteredRecentInstitutionIDs = recentInstitutionIDs.filter(
        (sourceInstitutionID) =>
          sourceInstitutions.hasOwnProperty(sourceInstitutionID),
      );
      return {
        ...state,
        fetching: false,
        error: false,
        sourceInstitutions: sourceInstitutions,
        selectedInstitution: filteredSelectedInstitution,
        recentInstitutionIDs: filteredRecentInstitutionIDs,
      };
    }

    case FETCH_SOURCE_INSTITUTIONS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }

    case SET_SELECTED_INSTITUTION: {
      const institutionID = action.institutionID;
      const selectedInstitution = state.sourceInstitutions[institutionID];
      if (institutionID) {
        const recentInstitutionIDs = updateRecentInsitutionIDs(
          state,
          institutionID,
        );
        setLocalStorageSelectedInstitution(selectedInstitution);
        setLocalStorageRecentInstitutionIds(recentInstitutionIDs);

        return {
          ...state,
          selectedInstitution,
          recentInstitutionIDs,
        };
      } else {
        return {
          ...state,
          selectedInstitution: {},
        };
      }
    }

    case SET_RECENT_INSTITUTIONS: {
      const institutionIDs = action.institutionIDs;
      setLocalStorageRecentInstitutionIds(institutionIDs);
      return {
        ...state,
        recentInstitutionIDs: institutionIDs,
      };
    }

    default: {
      return state;
    }
  }
};

const updateRecentInsitutionIDs = (state, institutionID) => {
  const recentInstitutionIDs = cloneDeep(state.recentInstitutionIDs);
  if (recentInstitutionIDs.indexOf(institutionID) < 0) {
    recentInstitutionIDs.splice(0, 0, institutionID);
  }

  if (recentInstitutionIDs.length > 3) {
    recentInstitutionIDs.pop();
  }
  return recentInstitutionIDs;
};

export default sourceInstitutionReducer;
