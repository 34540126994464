import React from "react";
import i from "i18next";
import { connect } from "react-redux";
import {
  isFetchingSourceInstitutions,
  getSelectedSourceInstitution,
  getRecentSourceInstitutions,
  getSourceInstitutions,
} from "store/source-institution/sourceInstitutionSelector";
import { Row, Col } from "antd";
import FullPageSpinner from "common/ui/loading/FullPageSpinner";
import SearchContainer from "./search/search-container/SearchContainer";
import SearchResult from "./search/search-result/SearchResult";
import classes from "./CreditCalculator.module.scss";
import {
  getCreditArrangementsBySourceInsitutionId,
  isFetchingCreditArrangements,
} from "store/credit-arrangement/creditArrangementSelector";
import _, { isEmpty } from "lodash";
import { getCreditBookmarks } from "store/credit-bookmark/creditBookmarkSelector";
import { setRecentInstitutions } from "store/source-institution/sourceInstitutionAction";

import { ReactComponent as SearchIllustration } from "common/ui/icons/search-illustration.svg";
import ContactButton from "common/ui/button/ContactButton";
import { isApplicationFinishedLoadingSelector } from "utils/generalUtils";

class CreditCalculator extends React.Component {
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.isFetchingSourceInstitutions &&
      !this.props.isFetchingSourceInstitutions
    ) {
      const { recentSourceInstitutions, sourceInstitutions } = this.props;
      const sourceInstitutionIDs = sourceInstitutions.map((inst) => inst.ID);
      const filteredRecentInstitutionIDs = recentSourceInstitutions
        .filter((inst) => inst)
        .map((inst) => inst.ID)
        .filter((inst) => _.includes(sourceInstitutionIDs, inst));
      this.props.dispatch(setRecentInstitutions(filteredRecentInstitutionIDs));
    }
  };

  render() {
    let notFoundIcon = (
      <SearchIllustration className={classes.searchIllustrationIcon} />
    );
    const searchIconPath = "common.icons.search-empty";
    if (i.exists(searchIconPath)) {
      notFoundIcon = (
        <img
          alt="magnifying glass"
          src={`config/icons/${i.t(searchIconPath)}`}
          className={`search-empty-icon`}
        />
      );
    }
    return (
      <div className={classes.creditCalculatorContainer}>
        <div className={classes.calculatorSearchContainer}>
          <SearchContainer
            isMobile={this.props.isMobile}
            selectedSourceInstitution={this.props.selectedSourceInstitution}
          />
        </div>

        {!this.props.applicationHasLoaded ? (
          <FullPageSpinner />
        ) : isEmpty(this.props.selectedSourceInstitution) ? (
          <Row id="institution-empty">
            <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
            <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
              <div className={`${classes.notFoundContent} not-found-content`}>
                {notFoundIcon}
                <span
                  className={`${classes.searchDescription} search-description`}
                >
                  {i.t("home.isEmpty.prompt")}
                </span>
                <ContactButton style={{ marginBottom: 140 }} />
              </div>
            </Col>
          </Row>
        ) : (
          <SearchResult
            selectedSourceInstitution={this.props.selectedSourceInstitution}
            creditArrangements={this.props.creditArrangements}
            creditBookmarks={this.props.creditBookmarks}
            isMobile={this.props.isMobile}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedSourceInstitution = getSelectedSourceInstitution(state);
  return {
    isFetchingSourceInstitutions: isFetchingSourceInstitutions(state),
    isFetchingCreditArrangements: isFetchingCreditArrangements(state),
    selectedSourceInstitution,
    recentSourceInstitutions: getRecentSourceInstitutions(state),
    sourceInstitutions: getSourceInstitutions(state),
    creditArrangements: getCreditArrangementsBySourceInsitutionId(
      selectedSourceInstitution.ID,
    )(state),
    creditBookmarks: getCreditBookmarks(state),
    applicationHasLoaded: isApplicationFinishedLoadingSelector(state),
  };
};

export default connect(mapStateToProps)(CreditCalculator);
