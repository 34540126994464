import {
  // FETCH_URLS,
  FETCH_URLS_ERROR,
  FETCH_URLS_SUCCESS,
  FETCH_URLS_PENDING,
} from "./urlAction";

const initialState = {
  urls: {},
  error: false,
  fetching: false,
};

export const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    // case FETCH_URLS:
    //   return {
    //     ...state,
    //   };
    case FETCH_URLS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_URLS_ERROR:
      return {
        ...state,
        urls: {},
        error: true,
        fetching: false,
      };
    case FETCH_URLS_SUCCESS:
      return {
        ...state,
        urls: {
          ...action.data.urlMap,
        },
        error: false,
        fetching: false,
      };
    default:
      return {
        ...state,
      };
  }
};
