import React from "react";
import { AutoComplete, Input } from "antd";
import { isEmpty } from "lodash";
import { Search, X } from "react-feather";
import i from "i18next";
import ContactButton from "common/ui/button/ContactButton";

const InstitutionTypeahead = (props) => {
  // TODO: replace prefix and suffix
  let prefix = <Search size={18} style={iconStyle} />;

  if (i.exists("common.icons.institution-search-prefix")) {
    prefix = (
      <img
        alt={`${i.t("common.icons.institution-search-prefix.alt")}`}
        src={`config/icons/${i.t(
          "common.icons.institution-search-prefix.src",
        )}`}
        className={"institution-search-prefix-icon"}
      />
    );
  }

  const suffix = !isEmpty(props.value) ? (
    <X
      size={18}
      onClick={() => {
        props.onClear();
      }}
    />
  ) : null;

  const notFoundContent = (
    <div style={notFoundContainerStyle}>
      <div style={notFoundTitleStyle} className="noresult-title">
        {i.t("home.institutionSearch.notFoundContent.heading")}
      </div>
      <div className="noresult-body">
        {i.t("home.institutionSearch.notFoundContent.description")}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px 16px",
        }}
      >
        <ContactButton />
      </div>
    </div>
  );

  return (
    <div style={{ width: "100%", marginBottom: 20 }}>
      <AutoComplete
        style={autoCompleteStyle}
        options={props.options}
        filterOption={props.filterOption}
        dropdownStyle={dropdownStyle}
        dropdownClassName="institution-typeahead-dropdown"
        notFoundContent={notFoundContent}
        value={props.value}
        onSelect={props.onSelect}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      >
        <Input
          aria-label="Search for institution"
          placeholder={props.placeholder}
          style={inputStyle}
          className="search-input"
          prefix={prefix}
          suffix={suffix}
          title="Institution search"
        />
      </AutoComplete>
    </div>
  );
};

export default InstitutionTypeahead;

const autoCompleteStyle = {
  width: "100%",
};

const inputStyle = {
  width: "100%",
  // borderRadius: 26,
  height: 52,
  textAlign: "center",
};

const iconStyle = {
  color: "#637381",
  cursor: "pointer",
  margin: "0px 18px",
};

const dropdownStyle = {
  border: "1px solid #C4CDD5",
  borderRadius: 3,
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
  fontSize: 16,
};

const notFoundContainerStyle = {
  padding: 32,
  color: "#212B36",
};

const notFoundTitleStyle = {
  fontSize: 20,
  marginBottom: 20,
};
