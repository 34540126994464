import { CREDIT_TYPES } from "common/model/creditType";
import { SET_SEARCH_TAB, SET_SEARCH_TERM } from "./searchAction";

const initialState = {
  searchTab: CREDIT_TYPES.ARTICULATION,
  searchTerm: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TAB: {
      return {
        ...state,
        searchTab: action.searchTab,
      };
    }

    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;
