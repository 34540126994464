import React from "react";
import { Spin } from "antd";
import { selectUrls } from "store/custom-url/urlSelector";
import { connect } from "react-redux";
import _ from "lodash";

const GoToMainSiteLink = ({ urls, style, children }) => {
  const GoToMainSiteLinkKey = "contact";
  const contactData = urls[GoToMainSiteLinkKey];
  if (_.isEmpty(urls) || _.isEmpty(contactData)) return <Spin />;

  const { url, alt } = contactData;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      alt={alt}
      style={style}
    >
      {children}
    </a>
  );
};

const mapStoreToProps = (store) => {
  return {
    urls: selectUrls(store),
  };
};

export default connect(mapStoreToProps)(GoToMainSiteLink);
