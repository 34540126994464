import React from "react";
import i from "i18next";
import { Helmet } from "react-helmet";
import SavedCreditItems from "components/saved-credits/SavedCreditItems";

class SavedCredits extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{`Saved - ${i.t("appName")}`}</title>
        </Helmet>
        <SavedCreditItems isMobile={this.props.isMobile} />
      </>
    );
  }
}

export default SavedCredits;
