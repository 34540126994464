import React from "react";
import i from "i18next";
import { Helmet } from "react-helmet";
import HelpInstruction from "components/help/HelpInstruction";

class Help extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{`Help - ${i.t("appName")}`}</title>
        </Helmet>
        <HelpInstruction />
      </>
    );
  }
}

export default Help;
