import React, { useCallback, useEffect, useState } from "react";
import i from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecentSourceInstitutions,
  getSelectedSourceInstitution,
  getSourceInstitutionsGroupedByCountry,
} from "store/source-institution/sourceInstitutionSelector";
import { setSelectedInstitution } from "store/source-institution/sourceInstitutionAction";
import InstitutionTypeahead from "./InstitutionTypeahead";
import { ReactComponent as Building } from "common/ui/icons/building.svg";
import { Clock } from "react-feather";
import { isEmpty, keys, reduce } from "lodash";
import { Typography } from "antd";

const { Paragraph } = Typography;

const buildFilter = (query) => (institution) => {
  if (!query) return true;
  if (!institution) return false;
  const fields = [institution.displayName, institution.countryName];
  return fields.some((field) => field.toLowerCase().indexOf(query) >= 0);
};

const isRecent = (country) => country === "RECENT";

const getOptions = (query, recents, sources) => {
  const q = query?.toLowerCase() || "";
  const r = recents || [];
  const s = sources || {};

  let count = 0;
  const countries = isEmpty(r) ? s : { RECENT: r, ...s };
  const options = reduce(
    keys(countries),
    (result, country) => {
      const institutions = (countries[country] || []).filter(buildFilter(q));
      if (!isEmpty(institutions)) {
        result[country] = institutions;
        if (!isRecent(country)) {
          count += institutions.length;
        }
      }
      return result;
    },
    {},
  );

  return { options, count };
};

const display = (options, mobile) => {
  const items = (institutions, recent = false) => {
    const style = {
      marginRight: 17,
      position: "relative",
      top: 3,
      color: "#637381",
    };

    let recentIcon = <Clock size={18} style={style} alt="clock" />;
    let defaultIcon = <Building style={style} alt="building" />;

    const recentPath = "common.icons.institution-search-dropdown-item-recent";

    const defaultPath = "common.icons.institution-search-dropdown-item";
    if (i.exists(recentPath)) {
      recentIcon = (
        <img
          alt={`${i.t(recentPath + ".alt")}`}
          src={`config/icons/${i.t(recentPath + ".src")}`}
          className="institution-search-dropdown-item-recent-icon"
        />
      );
    }
    if (i.exists(defaultPath)) {
      defaultIcon = (
        <img
          alt={`${i.t(defaultPath + ".alt")}`}
          src={`config/icons/${i.t(defaultPath + ".src")}`}
          className="institution-search-dropdown-item-icon"
        />
      );
    }

    return institutions.map((institution) => {
      const value = (recent ? "recent-" : "") + institution?.ID;
      const label = (
        <div style={{ display: "flex" }}>
          {!mobile && <div>{recent ? recentIcon : defaultIcon}</div>}
          <div>
            <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              style={{ margin: 0 }}
            >
              {institution?.displayName}
              {recent ? (
                <span style={{ marginLeft: 8, color: "#637381", fontSize: 14 }}>
                  {institution?.countryName}
                </span>
              ) : null}
            </Paragraph>
          </div>
        </div>
      );
      return { key: value, value, label };
    });
  };

  return keys(options).map((country) => {
    const institutions = options[country];
    return {
      key: country,
      label: <strong>{country.toUpperCase()}</strong>,
      options: items(institutions, isRecent(country)),
    };
  });
};

const InstitutionSearch = ({ isMobile }) => {
  // dispatch
  const dispatch = useDispatch();
  // redux
  const sources = useSelector((state) =>
    getSourceInstitutionsGroupedByCountry(state),
  );
  const recents = useSelector((state) => getRecentSourceInstitutions(state));
  const selected = useSelector((state) => getSelectedSourceInstitution(state));
  // state
  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [values, setValues] = useState(getOptions(query, recents, sources));
  // actions
  const select = useCallback(
    (id) => dispatch(setSelectedInstitution(id)),
    [dispatch],
  );

  const onSelect = useCallback(
    (id, option) => {
      const name =
        option.label.props.children[1].props.children.props.children[0];
      const recent = id.includes("recent");
      const identifier = recent ? id.split("-")[1] : id;
      setQuery(name);
      select(identifier);
    },
    [select],
  );

  const onChange = useCallback(
    (value) => {
      if (searching) {
        setQuery(value);
      }
    },
    [searching],
  );

  const onBlur = useCallback(() => {
    const value = selected?.displayName || "";
    setQuery(value);
    setSearching(false);
  }, [selected]);

  const onFocus = useCallback(() => setSearching(true), []);

  const onClear = useCallback(() => {
    select(null);
    setQuery("");
  }, [select]);

  // effects
  // update options etc if query, recents or sources changes.
  useEffect(
    () => setValues(getOptions(query, recents, sources)),
    [query, recents, sources],
  );

  const { options } = values;
  const rendered = display(options, isMobile);
  const value = searching ? query : selected?.displayName || "";

  return (
    <InstitutionTypeahead
      placeholder={i.t("home.institutionSearch.placeholder")}
      options={rendered}
      onSelect={onSelect}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onClear={onClear}
      value={value}
    />
  );
};

export default InstitutionSearch;
