import React from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

const WithWindowDimensions = (WrappedComponent) => {
  const HocComponent = (props) => {
    const { width, height } = useWindowDimensions();

    return <WrappedComponent width={width} height={height} {...props} />;
  };

  return HocComponent;
};

export default WithWindowDimensions;
