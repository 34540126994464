import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";
import SearchResultTab from "./SearchResultTab";
import { getSearchTerm, getSearchTab } from "store/search/searchSelector";
import { setSearchTerm, setSearchTab } from "store/search/searchAction";
import classes from "./SearchResult.module.scss";

class SearchResult extends React.Component {
  render() {
    const {
      selectedSourceInstitution,
      creditArrangements,
      creditBookmarks,
      isMobile,
      searchTab,
    } = this.props;

    const articulations = get(creditArrangements, "articulations", []);
    const precedents = get(creditArrangements, "precedents", []);

    const filteredArticulations = this.getFilteredArticulations(articulations);
    const filteredPrecedents = this.getFilteredPrecedents(precedents);

    const numberOfArticulations = filteredArticulations.length || 0;
    const numberOfPrecedents = filteredPrecedents.length || 0;
    const groupedArticulations = this.getArticulationsGroupedBySourceCourse(
      filteredArticulations,
    );

    return (
      <div id="search-result" className={classes.searchResultContainer}>
        <Row style={{ width: "100%" }}>
          <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
          <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
            <SearchResultTab
              activeTab={searchTab}
              numberOfArticulations={numberOfArticulations}
              numberOfPrecedents={numberOfPrecedents}
              groupedArticulations={groupedArticulations}
              articulations={filteredArticulations}
              precedents={filteredPrecedents}
              selectedSourceInstitution={selectedSourceInstitution}
              creditBookmarks={creditBookmarks}
              isMobile={isMobile}
              onTabChange={this.onTabChange}
            />
          </Col>
        </Row>
      </div>
    );
  }

  onTabChange = (tabKey) => {
    this.props.onSetSearchTab(tabKey);
  };

  onCourseSearchInputChange = (event) => {
    this.props.onSetSearchTerm(event.target.value);
  };

  onCourseSearchInputClear = () => {
    this.props.onSetSearchTerm("");
  };

  getFilteredArticulations = (articulations) => {
    const searchTerm = (this.props.searchTerm || "").trim().toLowerCase();
    const filteredArticulations = articulations.filter((articulation) => {
      const sourceMatched = articulation.arrangements.some((arrangement) => {
        const sourceSubjectMatched = articulation.credentials.some(
          (credential) => {
            const displayName = credential.data.unitName || "";
            const displayCode = credential.data.unitCode || "";
            return (
              displayName.toLowerCase().includes(searchTerm) ||
              displayCode.toLowerCase().includes(searchTerm)
            );
          },
        );
        const targetSubjectMatched = arrangement.subjects.some((subject) => {
          const displayName = subject.name || "";
          const displayCode = subject.ID || "";
          return (
            displayName.toLowerCase().includes(searchTerm) ||
            displayCode.toLowerCase().includes(searchTerm)
          );
        });
        // const articulationCricosMatched -

        return sourceSubjectMatched || targetSubjectMatched;
      });

      const targetMatched =
        articulation.course.name.includes(searchTerm) ||
        articulation.course.ID.includes(searchTerm) ||
        articulation.course.cricosCodes.some((code) =>
          code.toLowerCase().includes(searchTerm),
        );
      return sourceMatched || targetMatched;
    });
    return filteredArticulations;
  };

  getFilteredPrecedents = (precedents) => {
    const searchTerm = (this.props.searchTerm || "").trim().toLowerCase();

    const filteredPrecdents = precedents.filter((precedent) => {
      const sourceSubjectMatched = precedent.credentials.some((credential) => {
        const displayName = credential.data.unitName || "";
        const displayCode = credential.data.unitCode || "";
        return (
          displayName.toLowerCase().includes(searchTerm) ||
          displayCode.toLowerCase().includes(searchTerm)
        );
      });

      const targetSubjectMatched = precedent.subjects.some((subject) => {
        const displayName = subject.name || "";
        const displayCode = subject.ID || "";
        return (
          displayName.toLowerCase().includes(searchTerm) ||
          displayCode.toLowerCase().includes(searchTerm)
        );
      });

      return sourceSubjectMatched || targetSubjectMatched;
    });
    return filteredPrecdents;
  };

  getArticulationsGroupedBySourceCourse = (articulations) => {
    const articulationsMap = articulations.reduce(
      (articulationsMap, articulation) => {
        const newArticulationsMap = { ...articulationsMap };
        const credentialIssuerID =
          articulation.credentials[0].data.credentialIssuerID;
        const courseName = articulation.credentials[0].data.unitName;
        const key = `${credentialIssuerID} - ${courseName}`;
        const articulationKey = newArticulationsMap[key];
        if (articulationKey) {
          newArticulationsMap[key] = [...articulationKey, articulation];
        } else {
          newArticulationsMap[key] = [articulation];
        }
        return newArticulationsMap;
      },
      {},
    );
    return articulationsMap;
  };
}

const mapStateToProps = (state) => {
  return {
    searchTerm: getSearchTerm(state),
    searchTab: getSearchTab(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSearchTerm: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
    onSetSearchTab: (creditType) => dispatch(setSearchTab(creditType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
