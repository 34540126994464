import { CREDIT_TYPES } from "common/model/creditType";

export const getTotalCreditPoints = (creditItem) => {
  if (!creditItem) {
    return 0;
  }
  if (creditItem.arrangements) {
    // Case for Articulation
    return creditItem.arrangements.reduce(
      (total, arrangement) =>
        total + arrangement.specified + arrangement.unspecified,
      0,
    );
  } else if (creditItem.subjects) {
    // Case for Precedent
    return creditItem.subjects.reduce(
      (total, subject) => total + subject.creditPoints,
      0,
    );
  }
};

export const isCreditItemExistsInBookmarks = (
  creditBookMarks,
  sourceInstitutionID,
  creditItemID,
  creditType,
) => {
  if (!creditBookMarks) {
    return false;
  }

  const creditItems = creditBookMarks[sourceInstitutionID] || [];
  return creditItems.some(
    (creditItem) =>
      creditItem.creditItemID === creditItemID &&
      creditItem.creditType === creditType,
  );
};

export const isArticulation = (creditType) => {
  return CREDIT_TYPES.ARTICULATION === creditType;
};

export const isPrecedent = (creditType) => {
  return CREDIT_TYPES.PRECEDENT === creditType;
};
