/**
 * The base type of all analytics events; defines the common interface.
 */
class AnalyticsEvent {
    constructor(type) {
        this._type = type
    }

    type = () => this._type
    args = () => ({})
    toString = () => `${this.type()} => ${JSON.stringify(this.args())}`
}


/**
 * Invoked upon user conversion.
 */
export const ANALYTICS_EVENT_CONVERSION = 'conversion';

export class ConversionEvent extends AnalyticsEvent {
    constructor(source) {
        super(ANALYTICS_EVENT_CONVERSION);
        this._source = source
    }

    args = () => ({ source: this._source })
}

/**
 * Invoked upon user submitted searches.
 */
export const ANALYTICS_EVENT_SEARCH = 'search';

export class SearchEvent extends AnalyticsEvent {
    constructor(scope, count, parameters) {
        super(ANALYTICS_EVENT_SEARCH);
        this._scope = scope;
        this._count = count;
        this._parameters = parameters;
    }

    args = () => ({ scope: this._scope, parameters: this._parameters, count: this._count });
}