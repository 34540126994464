import { createSelector } from "reselect";
import { flatten } from "lodash";

export const getCreditBookmarks = (state) =>
  state.creditBookmarkState.creditBookmarks;

export const getTotalNumberOfCreditBookmarks = createSelector(
  // getCreditBookmarks,
  (state) => state,
  (state) => {
    const creditBookmarks = state.creditBookmarkState.creditBookmarks;
    //only count bookmarks exist in the datasource as well
    const sourceInstitutionsMap =
      state.sourceInstitutionState.sourceInstitutions;
    const bookmarks =
      flatten(
        (
          Object.keys(creditBookmarks).filter(
            (sourceInstitutionID) => sourceInstitutionsMap[sourceInstitutionID],
          ) || []
        ).map((sourceInstitutionID) => {
          return creditBookmarks[sourceInstitutionID];
        }),
      ) || [];

    return bookmarks.length;
  },
);
