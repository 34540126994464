import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Error404.module.scss';

class Error404 extends React.Component {
  render() {
    return (
      <div className={classes.errorMessageContainer}>
        <div className={classes.title}>
          We can’t find the page you’re looking for
        </div>
        <div className={classes.homeLink}>
          <Link to="/home">Go to homepage</Link>
        </div>
      </div>
    );
  }
}

export default Error404;
