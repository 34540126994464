import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from 'store/rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

let store;

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const reduxFreeze = require('redux-freeze');
  middlewares.push(reduxFreeze);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
} else {
  store = createStore(rootReducer, applyMiddleware(...middlewares));
}

sagaMiddleware.run(rootSaga);

export default store;
