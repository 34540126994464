import React from "react";
import { Tabs, Tooltip } from "antd";
import { isArray } from "lodash";
import i from "i18next";
import ResultNotFound from "./ResultNotFound";
import classes from "./SearchResultTab.module.scss";
import {
  ArticulationCreditList,
  PrecedentCreditList,
} from "components/credits/CreditList";
import { CREDIT_TYPES } from "common/model/creditType";
import { StickyContainer, Sticky } from "react-sticky";

import { ReactComponent as Info } from "common/ui/icons/fa-info-circle.svg";
import { EllipsisOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const SearchResultTab = (props) => {
  const {
    numberOfArticulations,
    numberOfPrecedents,
    groupedArticulations,
    articulations,
    precedents,
    selectedSourceInstitution,
    creditBookmarks,
    isMobile,
    activeTab,
  } = props;

  const resultNotFoundContent = (
    <div className={classes.resultNotFoundContainer}>
      <ResultNotFound />
    </div>
  );

  const articulationTooltipContent = i.t(
    "home.searchResultTab.viewArticulationsTab.tooltip",
    {
      returnObjects: true,
    },
  );
  const precedentTooltipContent = i.t(
    "home.searchResultTab.viewPrecedentsTab.tooltip",
    {
      returnObjects: true,
    },
  );

  let articulationTooltips = null;
  let precedentTooltips = null;
  if (isArray(articulationTooltipContent)) {
    articulationTooltips = (
      <Tooltip
        overlayStyle={{ fontSize: 14 }}
        title={
          <div>
            {articulationTooltipContent.map((li, i) => (
              <div key={`${i}${li}`}>{li}</div>
            ))}
          </div>
        }
        placement="right"
      >
        <Info className={classes.tabTooltipIcon} />
      </Tooltip>
    );
  }
  if (isArray(precedentTooltipContent)) {
    precedentTooltips = (
      <Tooltip
        overlayStyle={{ fontSize: 14 }}
        title={
          <div>
            {precedentTooltipContent.map((li, i) => (
              <div key={`${i}${li}`}>{li}</div>
            ))}
          </div>
        }
        placement="right"
      >
        <Info className={classes.tabTooltipIcon} />
      </Tooltip>
    );
  }

  const renderTabBar = (props, DefaultTabBar) => {
    return (
      <Sticky>
        {({ style, distanceFromTop }) => {
          let top = 0;
          let width = undefined;
          //  let backgroundColor = style.background || style.backgroundColor;
          let position = "static";
          const headerHeight =
            document.getElementById("custom-header").offsetHeight;
          if (isMobile) {
            top = headerHeight;
            width = "100vw";
            if (distanceFromTop <= top) {
              position = "fixed";
            }
          } else {
            if (distanceFromTop <= top) {
              top += headerHeight;
              position = "fixed";
              width = style.width;
            }
          }
          const defaultTabBarStyle = {
            ...style,
            display: "flex",
            justifyContent: "center",
            margin: 0,
            zIndex: 1,
            backgroundColor: "white",
            position,
            top,
            width,
          };
          return <DefaultTabBar {...props} style={defaultTabBarStyle} />;
        }}
      </Sticky>
    );
  };

  const tabs = (
    <Tabs
      id="custom-tab-container"
      centered
      defaultActiveKey={activeTab}
      renderTabBar={renderTabBar}
      onChange={props.onTabChange}
      moreIcon={<EllipsisOutlined />}
    >
      <TabPane
        tab={
          <TabPaneTab
            iconPath="common.icons.articulations-tab"
            title={i.t("home.searchResultTab.viewArticulationsTab.title")}
            numberOfResults={numberOfArticulations}
          />
        }
        key={CREDIT_TYPES.ARTICULATION}
        className={classes.tabpane}
      >
        <div className={`${classes.tabContentIntro} tab-content-intro`}>
          <span>
            {i.t("home.searchResultTab.viewArticulationsTab.description")}
            {articulationTooltips}
          </span>
        </div>
        {activeTab === CREDIT_TYPES.ARTICULATION && (
          <>
            {numberOfArticulations === 0 ? (
              resultNotFoundContent
            ) : (
              <div className={classes.resultListContainer}>
                <ArticulationCreditList
                  articulations={articulations}
                  groupedArticulations={groupedArticulations}
                  selectedSourceInstitution={selectedSourceInstitution}
                  creditBookmarks={creditBookmarks}
                  isMobile={isMobile}
                />
              </div>
            )}
          </>
        )}
      </TabPane>

      <TabPane
        tab={
          <TabPaneTab
            iconPath="common.icons.precedents-tab"
            title={i.t("home.searchResultTab.viewPrecedentsTab.title")}
            numberOfResults={numberOfPrecedents}
          />
        }
        key={CREDIT_TYPES.PRECEDENT}
        className={classes.tabpane}
      >
        <div className={`${classes.tabContentIntro} tab-content-intro`}>
          <span>
            {i.t("home.searchResultTab.viewPrecedentsTab.description")}
            {precedentTooltips}
          </span>
        </div>
        {activeTab === CREDIT_TYPES.PRECEDENT && (
          <>
            {numberOfPrecedents === 0 ? (
              resultNotFoundContent
            ) : (
              <div className={classes.resultListContainer}>
                <PrecedentCreditList
                  precedents={precedents}
                  selectedSourceInstitution={props.selectedSourceInstitution}
                  creditBookmarks={creditBookmarks}
                  isMobile={isMobile}
                />
              </div>
            )}
          </>
        )}
      </TabPane>
    </Tabs>
  );

  return (
    <div className={classes.rootTab} id="custom-tabs">
      <StickyContainer>{tabs}</StickyContainer>
    </div>
  );
};

export default SearchResultTab;

const TabPaneTab = ({ title, numberOfResults, iconPath }) => {
  let prefix = null;
  if (i.exists(iconPath)) {
    prefix = (
      <img
        alt={`${i.t(iconPath + ".alt")}`}
        src={`config/icons/${i.t(iconPath + ".src")}`}
        className={`tab-icon`}
      />
    );
  }
  return (
    <span className={`${classes.tabContent} search-result-tab-title`}>
      {prefix}
      {title}
      <span className={`${classes.tabBadge} search-result-tab-count`}>
        {numberOfResults}
      </span>
    </span>
  );
};
