import React from "react";
import CreditItemDetails from "components/credits/credit-details/CreditItemDetails";
import { CREDIT_TYPES } from "common/model/creditType";

class CreditDetails extends React.Component {
  render() {
    const creditItemID = this.props.match.params.creditItemID;
    const isArticulation = this.props.location.pathname.includes(
      CREDIT_TYPES.ARTICULATION.toLowerCase(),
    );
    const isMobile = this.props.isMobile;

    return (
      <CreditItemDetails
        creditItemID={creditItemID}
        isArticulation={isArticulation}
        isMobile={isMobile}
      />
    );
  }
}

export default CreditDetails;
