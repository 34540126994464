export const FETCH_URLS = "FETCH_URLS";
export const FETCH_URLS_SUCCESS = "FETCH_URLS_SUCCESS";
export const FETCH_URLS_ERROR = "FETCH_URLS_ERROR";
export const FETCH_URLS_PENDING = "FETCH_URLS_PENDING";
export const fetchUrls = () => {
  return {
    type: FETCH_URLS,
  };
};
export const fetchUrlsSuccess = (urlMap) => {
  return {
    type: FETCH_URLS_SUCCESS,
    data: {
      urlMap: urlMap,
    },
  };
};

export const fetchUrlsError = (error) => {
  return {
    type: FETCH_URLS_ERROR,
    data: {
      error: error,
    },
  };
};

export const fetchUrlsPending = () => {
  return {
    type: FETCH_URLS_PENDING,
  };
};
