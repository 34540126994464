import React, { Fragment } from "react";

const SubjectDetailList = (props) => {
  const subjects = props.subjects || [];

  return subjects.map((subject, index, array) => {
    return (
      <Fragment key={subject.ID}>
        <span>
          <span style={{ fontWeight: 500, fontSize: 16 }}>{subject.name}</span>{" "}
          {subject.type === "UNSPECIFIED" ? null : (
            <>
              &middot;{" "}
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                {subject.ID}
              </span>
            </>
          )}
        </span>
        {index < array.length - 1 && array.length !== 1 && (
          <div key={index} style={{ fontSize: 12, color: "#637381" }}>
            AND
          </div>
        )}
      </Fragment>
    );
  });
};

export default SubjectDetailList;
