import React from "react";
import { Row, Col } from "antd";
import classes from "./SearchContainer.module.scss";
import InstitutionSearch from "components/forms/institution-typeahead/InstitutionSearch";
import i from "i18next";

// additional elements
import CourseSearchInput from "components/forms/course-search-input/CourseSearchInput";
import { getSearchTerm, getSearchTab } from "store/search/searchSelector";
import { setSearchTerm, setSearchTab } from "store/search/searchAction";
import { connect } from "react-redux";

class SearchContainer extends React.Component {
  render() {
    const { searchTerm } = this.props;

    const description =
      i.t("home.searchDescription", {
        returnObjects: true,
      }) || [];
    return (
      <Row
        id="search-container"
        className={`${classes.searchContainer} title-section`}
      >
        <Col sm={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }} />
        <Col sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 18 }}>
          <div
            style={{
              maxWidth: 1120,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span className={`${classes.searchTitle} page-title`}>
              {i.t("home.searchTitle")}
            </span>
            <div className={`desc-text`} id="desc-text">
              {description.map((description) => (
                <p key={description}>{`${description} `}</p>
              ))}
            </div>
            <InstitutionSearch isMobile={this.props.isMobile} />

            <CourseSearchInput
              placeholder={i.t("home.courseSearch.placeholder")}
              value={searchTerm}
              onChange={this.onCourseSearchInputChange}
              onClear={this.onCourseSearchInputClear}
            />
          </div>
        </Col>
      </Row>
    );
  }

  onCourseSearchInputChange = (event) => {
    this.props.onSetSearchTerm(event.target.value);
  };

  onCourseSearchInputClear = () => {
    this.props.onSetSearchTerm("");
  };
}

const mapStateToProps = (state) => {
  return {
    searchTerm: getSearchTerm(state),
    searchTab: getSearchTab(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSearchTerm: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
    onSetSearchTab: (creditType) => dispatch(setSearchTab(creditType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
