import { createSelector } from "reselect";
import { groupBy, sortBy } from "lodash";

export const getSourceInstitutionsMap = (state) =>
  state.sourceInstitutionState.sourceInstitutions;

export const getSelectedSourceInstitution = (state) =>
  state.sourceInstitutionState.selectedInstitution;

export const isFetchingSourceInstitutions = (state) =>
  state.sourceInstitutionState.fetching;

const getRecentSourceInstitutionIds = (state) =>
  state.sourceInstitutionState.recentInstitutionIDs;

export const getRecentSourceInstitutions = createSelector(
  getSourceInstitutionsMap,
  getRecentSourceInstitutionIds,
  (sourceInstitutionsMap, recentInstitutionIDs) => {
    return recentInstitutionIDs.map(
      (recentInstitutionId) => sourceInstitutionsMap[recentInstitutionId]
    );
  }
);

export const getSourceInstitutionById = (id) => {
  return createSelector(getSourceInstitutionsMap, (sourceInstitutionsMap) => {
    return sourceInstitutionsMap[id];
  });
};

export const getSourceInstitutions = createSelector(
  getSourceInstitutionsMap,
  (sourceInstitutionsMap) => {
    return Object.keys(sourceInstitutionsMap).map(
      (id) => sourceInstitutionsMap[id]
    );
  }
);

export const getSourceInstitutionsGroupedByCountry = createSelector(
  getSourceInstitutions,
  (sourceInstitutions) => {
    const institutionsMap = {};
    const instGroupedByCountry = groupBy(sourceInstitutions, "countryName");

    Object.keys(instGroupedByCountry).forEach((countryName) => {
      institutionsMap[countryName] = sortBy(instGroupedByCountry[countryName], [
        (institution) => {
          return (institution.displayName || "").toLowerCase();
        },
      ]);
    });

    return institutionsMap;
  }
);
