export const SET_SEARCH_TAB = 'SET_CURRENT_SEARCH_TAB';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const setSearchTab = (creditType) => {
  return {
    type: SET_SEARCH_TAB,
    searchTab: creditType,
  };
};

export const setSearchTerm = (searchTerm) => {
  return {
    type: SET_SEARCH_TERM,
    searchTerm,
  };
};
