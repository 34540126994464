import {
  ADD_CREDIT_TO_BOOKMARK_ACTION,
  REMOVE_CREDIT_FROM_BOOKMARK_ACTION,
  FETCH_CREDIT_BOOKMARKS,
} from './creditBookmarkAction';
import { cloneDeep, isEmpty } from 'lodash';
import { isCreditItemExistsInBookmarks } from 'utils/creditUtils';
import {
  setLocalStorageCreditBookmarks,
  getLocalStorageCreditBookmarks,
} from 'utils/localStorage';

const initialState = {
  creditBookmarks: {},
};

const creditBookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CREDIT_TO_BOOKMARK_ACTION: {
      const sourceInstitutionID = action.sourceInstitutionID;
      const creditItemID = action.creditItemID;
      const creditType = action.creditType;
      const timestamp = action.timestamp;

      const currentCreditBookmarks = cloneDeep(state.creditBookmarks);
      const creditItems = currentCreditBookmarks[sourceInstitutionID];

      if (isEmpty(creditItems)) {
        currentCreditBookmarks[sourceInstitutionID] = [
          { creditItemID, creditType, timestamp },
        ];
      } else {
        const existCreditItem = isCreditItemExistsInBookmarks(
          currentCreditBookmarks,
          sourceInstitutionID,
          creditItemID,
          creditType,
        );

        if (!existCreditItem) {
          currentCreditBookmarks[sourceInstitutionID] = [
            ...creditItems,
            { creditItemID, creditType, timestamp },
          ];
        }
      }

      setLocalStorageCreditBookmarks(currentCreditBookmarks);
      return {
        creditBookmarks: currentCreditBookmarks,
      };
    }

    case REMOVE_CREDIT_FROM_BOOKMARK_ACTION: {
      const { sourceInstitutionID, creditItemID, creditType } = action;

      const currentCreditBookmarks = cloneDeep(state.creditBookmarks);
      const creditItems = currentCreditBookmarks[sourceInstitutionID];
      if (!isEmpty(creditItems)) {
        const existCreditItem = isCreditItemExistsInBookmarks(
          currentCreditBookmarks,
          sourceInstitutionID,
          creditItemID,
          creditType,
        );

        if (existCreditItem) {
          if (creditItems.length === 1) {
            delete currentCreditBookmarks[sourceInstitutionID];
          } else {
            const filteredCreditItems = creditItems.filter(
              (creditItem) =>
                creditItem.creditItemID !== creditItemID ||
                creditItem.creditType !== creditType,
            );
            currentCreditBookmarks[sourceInstitutionID] = [
              ...filteredCreditItems,
            ];
          }
        }
      }

      setLocalStorageCreditBookmarks(currentCreditBookmarks);
      return {
        creditBookmarks: currentCreditBookmarks,
      };
    }

    case FETCH_CREDIT_BOOKMARKS: {
      const creditBookmarks = getLocalStorageCreditBookmarks();
      return {
        creditBookmarks,
      };
    }

    default: {
      return state;
    }
  }
};

export default creditBookmarkReducer;
