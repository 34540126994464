import React from "react";
import i from "i18next";
import { Input } from "antd";
import { BookOpen, X } from "react-feather";
import { isEmpty } from "lodash";
import classes from "../SearchStyles.modules.scss";

const CourseSearchInput = (props) => {
  let prefix = <BookOpen size={18} style={iconStyle} alt="open book" />;

  if (i.exists("common.icons.course-search-prefix")) {
    prefix = (
      <img
        alt={i.t("common.icons.course-search-prefix.alt")}
        src={`config/icons/${i.t("common.icons.course-search-prefix.src")}`}
        className={"course-search-prefix-icon"}
      />
    );
  }

  const suffix = !isEmpty(props.value) ? (
    <X size={18} onClick={props.onClear} />
  ) : null;

  return (
    <Input
      title="Course search"
      id="course-search-input"
      value={props.value}
      prefix={prefix}
      suffix={suffix}
      style={inputStyle}
      className={`search-input ${classes.search}`}
      placeholder={props.placeholder}
      onChange={props.onChange}
      aria-label="Search for course"
      autocomplete="off"
    />
  );
};

export default CourseSearchInput;

const inputStyle = {
  width: "100%",
  // borderRadius: 26,
  height: 52,
};

const iconStyle = {
  color: "#637381",
  cursor: "pointer",
  margin: "0px 18px",
};
