import { LoadingOutlined } from "@ant-design/icons";

import React, { Component } from "react";

export default class FullPageSpinner extends Component {
  render() {
    return (
      <div
        style={{
          width: "100vw",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingOutlined />
      </div>
    );
  }
}
