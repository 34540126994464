import { createSelector } from "reselect";

export const getCreditArrangementsMap = (state) =>
  state.creditArrangementState.creditArrangements;

export const isFetchingCreditArrangements = (state) =>
  state.creditArrangementState.fetching;

export const getCreditArrangementsBySourceInsitutionId = (ID) => {
  return createSelector(getCreditArrangementsMap, (creditArrangementsMap) => {
    return creditArrangementsMap[ID];
  });
};

export const getArticulationByID = (ID) => {
  return createSelector(getCreditArrangementsMap, (creditArrangementsMap) => {
    let foundArticulation;
    Object.keys(creditArrangementsMap).forEach((key) => {
      if (!foundArticulation) {
        foundArticulation = (
          creditArrangementsMap[key] || {}
        ).articulations.find((articulation) => articulation.ID === ID);
      }
    });
    return foundArticulation || {};
  });
};

export const getPrecedentByID = (ID) => {
  return createSelector(getCreditArrangementsMap, (creditArrangementsMap) => {
    let foundPrecedent;
    Object.keys(creditArrangementsMap).forEach((key) => {
      if (!foundPrecedent) {
        foundPrecedent = (creditArrangementsMap[key] || {}).precedents.find(
          (precedent) => precedent.ID === ID
        );
      }
    });
    return foundPrecedent || {};
  });
};
